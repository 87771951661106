<template>
	<div class="container container-left DesignEdge Space-leftNegative">
		<div class="row DesignEdge--wrapperRow">
			<div class="col-md-6 col-lg-5">
				<div class="align-items-start flex-column DesignEdge--menuWrapper">
					<!-- LOGO  -->
					<div class="offset-3 col-18 Text-center">
						<img
							:src="logo"
							alt="Omnevue"
							data-testid="asset-logo" />
					</div>
					<div class="col-24 DesignEdge--sticky">
						<slot name="nav" />
					</div>

					<!-- FOOTER  -->
					<div class="col-24 DesignEdge--footer">
						{{ currentDate.getFullYear() }} © ESGgen Limited (Omnevue)<br />
						{{ appDetails.name }} v.{{ appDetails.version }}<br />
					</div>
				</div>
			</div>

			<div class="col-md-18 col-lg-19 Space-top Space-bottomDouble">
				<slot name="header" />
				<slot name="main" />
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Design template for our applications. This is side-navigation on the left on the edge and full screen content
	 * @vue-prop {*} slot:nav slot for navigation
	 * @vue-prop {*} slot:main slot for the main content, likely router stuff
	 * @namespace Core_Design_Edge
	 */

	export default {
		name: 'DesignEdge',

		props: {
			/* PROP: logo image reference to be used
			 *
			 * @namespace Core_Design_Edge
			 * @property {URL} logo image reference to be used
			 */
			logo: {
				type: URL,
				required: true
			}
		},

		setup() {
			const appDetails = {
				name: BUILD_APP,
				version: BUILD_VERSION
			};
			const currentDate = new Date();

			return { appDetails, currentDate };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('DesignEdge') {
		height: 100vh;
		max-width: 100% !important;
		padding-right: 30px !important;

		@include element('wrapperRow') {
			min-height: 100%;
		}
		@include element('menuWrapper') {
			display: flex;
			height: 100%;
			background: var(--color-secondary);
			padding-top: var(--space-double);
			padding-bottom: var(--space-double);
			box-shadow: 2px 0px 3px 0px rgba(194, 194, 194, 1);
		}

		@include element('sticky') {
			position: sticky;
			top: var(--space-double);
			margin-bottom: var(--space-double);
		}

		@include element('footer') {
			margin-top: auto !important;
			font-size: var(--text-tiny);
			color: var(--color-grey600);
			text-align: center;
			bottom: var(--space-single);
			left: -15px;
		}
	}
</style>
