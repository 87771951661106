import * as Core from '@Core/index.js';

// STORE DEFINITIONS -------------------
/**
 * @exports Client_Store_Language
 * @namespace Client_Store_Language
 */
export const useLanguageStore = Core.Pinia.defineStore({
	id: 'language',
	state: () => ({
		config: {
			en: {
				name: 'English',
				icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="256" r="256" fill="#f0f0f0"/><g fill="#0052b4"><path d="M52.92 100.14a255.07 255.07 0 00-44.1 89.08H142l-89.08-89.08zM503.18 189.22a255.09 255.09 0 00-44.1-89.08L370 189.22h133.18zM8.82 322.78a255.1 255.1 0 0044.1 89.08l89.07-89.08H8.82zM411.86 52.92a255.08 255.08 0 00-89.08-44.1V142l89.08-89.08zM100.14 459.08a255.09 255.09 0 0089.08 44.1V370l-89.08 89.08zM189.22 8.82a255.1 255.1 0 00-89.08 44.1L189.22 142V8.81zM322.78 503.18a255.1 255.1 0 0089.08-44.1L322.78 370v133.18zM370 322.78l89.08 89.08a255.08 255.08 0 0044.1-89.08H370z"/></g><g fill="#d80027"><path d="M509.83 222.6H289.4V2.18a258.56 258.56 0 00-66.78 0V222.6H2.17a258.56 258.56 0 000 66.78H222.6v220.44a258.53 258.53 0 0066.78 0V289.4h220.44a258.53 258.53 0 000-66.78z"/><path d="M322.78 322.78l114.24 114.24a256.64 256.64 0 0015.05-16.43l-97.8-97.8h-31.49zM189.22 322.78L74.98 437.02a256.64 256.64 0 0016.43 15.05l97.8-97.8v-31.49zM189.22 189.22L74.98 74.98a256.64 256.64 0 00-15.05 16.43l97.8 97.8h31.49zM322.78 189.22L437.02 74.98a256.33 256.33 0 00-16.43-15.05l-97.8 97.8v31.49z"/></g></svg>'
			}
		},
		currentCode: '',
		string: {}
	}),

	actions: {
		/**
		 * Update existing language strings with new one from the Language Pack (the automated process builidng all *.lang files)
		 * @memberof Client_Store_Language
		 * @param {string} langCode new language to be loaded
		 */
		update(langCode) {
			const newLang = Core.Storage.local('language') || this.config[langCode] || 'en';
			app.log('LANGUAGE', 'Loading pack:', newLang);

			// load correct language
			import(`../languages/index.${newLang}.built.js`)
				.then((response) => {
					successResponse(this, response);
				})
				.catch((err) => {
					app.log('LANGUAGE', `Failed to load language pack:${newLang}`, err);
				});

			/**
			 * this is just abstraction of the success work to be done after importing a file.
			 * It sucks it's a function within a function, but I have no time to fix it now :D
			 * @param {object} state pass-in state
			 * @param {object} response response from import
			 */
			function successResponse(state, response) {
				app.log('LANGUAGE', 'Imported & installed pack:', newLang);
				Core.Storage.local('language', newLang);

				state.currentCode = newLang;

				// update state's strings
				state.string = response.default; // this is needed as it's deep object ($patch won't work correctly with it)
			}
		}
	}
});
