<template>
	<div
		v-for="(value, key) in activity"
		:key="key"
		class="ActivityLogEntry"
		data-testid="activity-log-entry">
		<!-- Activity pill  -->
		<transition-group name="slideAbsolute">
			<div
				v-for="(item, index) in value"
				:key="index"
				class="row ActivityLogEntry--pill"
				data-testid="activity-pill">
				<span
					data-testid="icon"
					class="col-3 ActivityLogEntry--icon"
					:class="
						activityLanguage.sectionActivity[item.actionType]?.icon || 'iconFont-edit'
					"></span>
				<p
					class="col-8 ActivityLogEntry--actionType Text-bold"
					:class="
						activityLanguage.sectionActivity[item.actionType]?.hideEmail &&
						'Space-right col-14'
					">
					{{
						activityLanguage.sectionActivity[item.actionType]?.message ||
						item.actionType
					}}
					<span
						v-if="item.product"
						class="ActivityLogEntry--productDetails"
						>- {{ item.product.name }}
						{{ item.product.type === 'ASSURE' ? 'vueESG' : '' }}
						{{ item.product.type === 'VUECO2E' ? 'vueCO2e' : '' }}
						{{ item.product.financialYear }}</span
					>
				</p>
				<p
					v-if="!activityLanguage.sectionActivity[item.actionType]?.hideEmail"
					class="col-6 ActivityLogEntry--userDisplayName Text-center">
					{{ item.userDisplayName }}
				</p>
				<div
					class="col-6 Text-center"
					:class="
						activityLanguage.sectionActivity[item.actionType]?.hideEmail ===
							'undefined' && 'Space-left'
					">
					<span class="ActivityLogEntry--timestamp">{{
						Core.Utils.humanTime(item.timestamp) || item.timestamp
					}}</span>
				</div>
				<span
					:class="{
						'is-expanded': item.showMetaData,
						hideChevron:
							Object.keys(item.actionMeta).length === 0 &&
							!activityLanguage.sectionActivity[item.actionType]?.note
					}"
					data-testid="chevron"
					class="col-1 iconFont-chevron ActivityLogEntry--chevron"
					@click="item.showMetaData = !item.showMetaData" />

				<!-- Meta data section -->
				<transition-group name="slideAbsolute">
					<div
						v-if="item.showMetaData"
						:key="index"
						data-testid="action-meta"
						class="row Text-small">
						<div class="row col-16 Space-top">
							<span
								v-for="(subValue, subKey) in item.actionMeta"
								:key="subKey">
								<b>{{ activityLanguage[subKey] }}</b
								>: {{ subValue }}
							</span>
							<span v-if="activityLanguage.sectionActivity[item.actionType]?.note">
								<b>Note: </b>
								{{ activityLanguage.sectionActivity[item.actionType]?.note }}</span
							>
						</div>
					</div>
				</transition-group>
			</div>
		</transition-group>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'ActivityLogEntry',

		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: activity object with all activities for display
			 * @property {object} activity used for showing all activities
			 */
			activity: {
				type: Object,
				required: true
			}
		},

		// ---------- SETUP ----------
		setup() {
			const language = useLanguageStore();
			const activityLanguage = language.string.cActivityLog;

			return { Core, activityLanguage };
		}
	};
</script>

<style lang="scss">
	@include block('ActivityLogEntry') {
		@include element('pill') {
			align-items: center;
			border-radius: var(--radius-big);
			padding: var(--space-single);

			justify-content: space-between;
		}
		@include element('actionType') {
			font-size: var(--text-small);
		}
		@include element('icon') {
			width: 40px;
			height: 40px;
			background-color: var(--color-greyStart);
			border: 1px solid var(--color-primary);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: var(--text-h3);
		}

		@include element('timestamp') {
			color: var(--color-grey500);
			font-size: var(--text-tiny);
		}
		@include element('userDisplayName') {
			font-size: var(--text-tiny);
		}
		@include element('line') {
			border-left: 2px solid var(--color-primary);
			margin: 8px 0;
			height: 12px;
			margin-left: 18.5px;
		}
		@include element('productDetails') {
			color: var(--color-grey500);
		}
		@include element('line-extended') {
			border-left: 2px solid var(--color-primary);
			border-bottom: 2px solid var(--color-primary);
			border-bottom-left-radius: 12px;
			margin-left: 18.5px;
			height: 15px;
			width: 25px;
		}
		@include element('chevron') {
			transform-origin: center center;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: var(--time-hover) ease-in;
			&:hover {
				cursor: pointer;
				color: var(--color-primary);
			}
			&.is-expanded {
				transform: rotate(180deg);
			}
			&.hideChevron {
				visibility: hidden;
			}
		}
	}
</style>
