<template>
	<div class="QrCode">
		<div
			:id="state.id"
			:style="size && `transform: scale(0.5); width:${size}px; height:${size}px;`"></div>

		<button
			v-if="showDownload"
			class="Btn Btn-small"
			:class="!darkTheme && 'Btn-outline'"
			@click="download">
			{{ language.string.cQrCode.downloadBtn }}
		</button>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * https://qr-code-styling.com
	 */

	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import iconForLight from '@Core/assets/logoSymbol.svg?url';
	import iconForDark from '@Core/assets/logoSymbol-inverted.svg?url';

	const FORMAT = 'png';

	// configure dark and light theme settings
	const DARK_THEME = {
		icon: iconForDark,
		dotsOptionsColor: '#cca2ec',
		backgroundOptionsColor: '#000',
		cornersSquareOptionsColor: '#ffffff',
		cornersDotOptionsColor: '#cca2ec'
	};

	const LIGHT_THEME = {
		icon: iconForLight,
		dotsOptionsColor: '#13294e',
		backgroundOptionsColor: '#fff',
		cornersSquareOptionsColor: '#13294e',
		cornersDotOptionsColor: '#8a4dff'
	};

	export default {
		name: 'QrCode',

		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: [darkTheme=false] of the user
			 * @namespace Core_QrCode
			 * @property {boolean} [darkTheme=false] of the user
			 */
			darkTheme: {
				required: false,
				type: Boolean,
				default: false
			},

			/**
			 * PROP: url to generate as QR Code
			 * @namespace Core_QrCode
			 * @property {string} url to generate as QR Code
			 */
			url: {
				required: true,
				type: String
			},

			/**
			 * PROP: [showDownload=false] show download button under the QR Code
			 * @namespace Core_QrCode
			 * @property {boolean} [showDownload=false] show download button under the QR Code
			 */
			showDownload: {
				required: false,
				type: Boolean,
				default: false
			},

			/**
			 * PROP: [size] change display size of the QR Code (not render size)
			 * @namespace Core_QrCode
			 * @property {number} [size] change display size of the QR Code (not render size)
			 */
			size: {
				required: false,
				type: Number,
				default: null
			},

			/**
			 * PROP: [downloadName=omnevue-qrcode] filename when downloading
			 * @namespace Core_QrCode
			 * @property {string} [downloadName=omnevue-qrcode] filename when downloading
			 */
			downloadName: {
				required: false,
				type: String,
				default: 'omnevue-qrcode'
			}
		},

		// ---------- SETUP ----------
		setup(props) {
			const language = useLanguageStore();

			const state = Core.Vue.reactive({
				id: Core.Utils.makeid(5),
				generated: false,
				loadedScript: false,
				theme: props.darkTheme ? DARK_THEME : LIGHT_THEME,
				sizeRatio: props.size ? (300 / props.size) * 100 : 1
			});

			let qrCodeClass;

			// import script dynamically only when this component in use
			Core.Vue.onBeforeMount(async () => {
				const qrclass = await import('qr-code-styling');
				generate(qrclass.default);
			});

			// watch for changes with url to re-render the QR code
			Core.Vue.watch(
				() => props.url,
				() => {
					qrCodeClass = null;
					state.generated = false;
					document.getElementById(state.id).innerHTML = ''; // clear old one
					generate();
				}
			);

			/**
			 * Generate the code
			 * @param {object} qrClass imported class with QR Code generator
			 */
			function generate(qrClass) {
				qrCodeClass = new qrClass({
					width: 300,
					height: 300,
					type: FORMAT,
					data: props.url,
					image: state.theme.icon,
					dotsOptions: {
						color: state.theme.dotsOptionsColor,
						type: 'dots'
					},
					backgroundOptions: {
						color: state.theme.backgroundOptionsColor
					},
					imageOptions: {
						margin: 10
					},
					cornersSquareOptions: {
						type: 'square',
						color: state.theme.cornersSquareOptionsColor
					},
					cornersDotOptions: {
						type: 'square',
						color: state.theme.cornersDotOptionsColor
					}
				});

				qrCodeClass.append(document.getElementById(state.id));
				state.generated = true;

				Core.Tracking.event('qrcode-generated');
			}

			/**
			 * triggers QR code canvas download
			 */
			function download() {
				qrCodeClass.download({ name: props.downloadName, extension: FORMAT });
				Core.Tracking.event('qrcode-downloaded');
			}

			return { Core, language, state, download };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss"></style>
