import * as Cookie from '@Core/modules/Cookie/index.js';

export const nonProduction =
	window.location.hostname.includes('non-prod') ||
	window.location.hostname.includes('sandbox') ||
	window.location.hostname.includes('localhost');

const canAccessNonProd =
	nonProduction &&
	Cookie.get('user') !== 'unknown' &&
	!window.location.hostname.includes('showcase');

/**
 * Checks if we are on protected environment and if yes, we will take user to no access page
 */
export function blockAccess() {
	if (canAccessNonProd) {
		window.location.href = '/noAccess/index.html';
	}
}
