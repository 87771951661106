/**
 * @module Core/AppConfig
 */

import { CONFIG } from '@Core/config.js';

/**
 * config all configuration exported in the object
 * @type {object}
 */
export let config = {};

/**
 * Set the right environment, it will allow for overwtie on the localhost
 * @param {object} incomingConfig full config object from app's config.js file
 */
export function set(incomingConfig) {
	// environment switcher
	const envConfig = typeof window !== 'undefined' ? window.location.hostname : 'localhost';
	config = incomingConfig?.[envConfig] || {};
}

/**
 * Get product offering ID based on name and variant provided (needed for purchase flows)
 * @param {string} name name/type of the product
 * @param {string} [variant] of the product
 * @returns {string} id offeringId for a found product
 */
export function getProductOfferingId(name, variant) {
	const offering = config.PRODUCTS_OFFERING.filter((item) => {
		return item.type === name && item.variant === variant;
	});

	return offering?.[0].id || '';
}

/**
 * Return config from a specific ENV replacing with .env contents where applicable
 * @returns {object} Env specific config
 */
export function getEnvConfig() {
	const allConfig = CONFIG[import.meta.env?.VITE_ENV || 'DEV'];

	return {
		AWS_REGION: CONFIG.AWS_REGION,
		MAPS_KEY: CONFIG.MAPS_KEY,
		FUSE_API_KEY: CONFIG.FUSE_API_KEY,
		AWS_POOL_ID: import.meta.env?.VITE_AWS_POOL_ID || allConfig.AWS_POOL_ID,
		AWS_CLIENT_ID: import.meta.env?.VITE_AWS_CLIENT_ID || allConfig.AWS_CLIENT_ID,
		AUTH_DOMAIN: import.meta.env?.VITE_AUTH_DOMAIN || allConfig.AUTH_DOMAIN,
		DEVELOPERS_AWS_POOL_ID:
			import.meta.env?.VITE_DEVELOPERS_AWS_POOL_ID || allConfig.DEVELOPERS_AWS_POOL_ID,
		DEVELOPERS_AWS_CLIENT_ID:
			import.meta.env?.VITE_DEVELOPERS_AWS_CLIENT_ID || allConfig.DEVELOPERS_AWS_CLIENT_ID,
		DEVELOPERS_AUTH_DOMAIN:
			import.meta.env?.DEVELOPERS_AUTH__DOMAIN || allConfig.DEVELOPERS_AUTH_DOMAIN,
		CLIENT_API: import.meta.env?.VITE_CLIENT_API || allConfig.CLIENT_API,
		PHORGE_API: import.meta.env?.VITE_PHORGE_API || allConfig.PHORGE_API,
		DEVELOPERS_API: import.meta.env?.VITE_PHORGE_API || allConfig.DEVELOPERS_API,
		STRIPE_KEY: import.meta.env?.VITE_STRIPE_KEY || allConfig.STRIPE_KEY,
		PUBNUB_P: import.meta.env?.VITE_PUBNUB_P || allConfig.PUBNUB_P,
		PUBNUB_S: import.meta.env?.VITE_PUBNUB_S || allConfig.PUBNUB_S,
		PUBLIC_BUCKET: import.meta.env?.VITE_PUBLIC_BUCKET || allConfig.PUBLIC_BUCKET
	};
}

export const CORE_CONFIG = CONFIG;
