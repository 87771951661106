<template>
	<transition-group name="fade">
		<blueprint-loader
			v-if="loading"
			key="loading"
			:full-size="true" />

		<core-design-fullscreen
			v-else-if="design === 'fullScreen' && !loading"
			key="fullScreen">
			<template #header>
				<user-menu
					:first-name="Store.user.getUser?.firstName"
					:last-name="Store.user.getUser?.lastName" />
			</template>
			<template #default>
				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</template>
		</core-design-fullscreen>

		<core-design-no-nav
			v-else-if="design === 'noNav' && !loading"
			key="noNav">
			<template #header>
				<user-menu
					:first-name="Store.user.getUser?.firstName"
					:last-name="Store.user.getUser?.lastName" />
			</template>

			<template #main>
				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</template>

			<template #footer>
				<client-footer />
			</template>
		</core-design-no-nav>

		<core-design-single
			v-else-if="design === 'single' && !loading"
			key="single">
			<template
				v-if="isLoggedIn"
				#header>
				<div class="Text-right">
					<button
						class="Btn Btn-small Btn-outline Space-topDouble"
						@click="Core.Aws.Auth.logout()">
						{{ Store.language.string.Client.btnLogout }}
					</button>
				</div>
			</template>

			<template #main>
				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</template>
		</core-design-single>

		<core-design-default
			v-else-if="!loading"
			key="default">
			<template #header>
				<user-menu
					:first-name="Store.user.getUser?.firstName"
					:last-name="Store.user.getUser?.lastName" />
			</template>

			<template #nav>
				<client-nav-business />
				<client-navigation />
			</template>

			<template #main>
				<breadcrumbs v-if="$route.path !== '/dashboard'" />
				<router-view v-slot="{ Component }">
					<component :is="Component" />
				</router-view>
			</template>

			<template #footer>
				<client-footer />
			</template>
		</core-design-default>
	</transition-group>

	<core-error-handler key="errorHandle" />
	<core-alert key="alert" />
</template>

<script>
	import * as Core from '@Core/index.js';
	import * as Store from '@Client/store/index.js';
	import RouterPermissions from '@Client/modules/RouterPermissions/index.js';
	import InitialiseData from '@Client/modules/InitialiseData/index.js';
	import * as MaintenanceMode from '@Client/modules/MaintenanceMode/index.js';
	import * as FlowDetection from '@Client/modules/FlowDetection/index.js';
	import ClientNavBusiness from '@Client/components/ClientNavBusiness/index.vue';
	import ClientNav from '@Client/components/ClientNav/index.vue';
	import Footer from '@Client/components/Footer/index.vue';

	export default {
		//  ---------- COMPONENTS ----------
		components: {
			'core-design-default': Core.cDesign.Default,
			'core-design-fullscreen': Core.cDesign.Full,
			'core-design-no-nav': Core.cDesign.NoNav,
			'core-design-single': Core.cDesign.Single,
			'client-footer': Footer,
			'client-nav-business': ClientNavBusiness,
			'user-menu': Core.cUserMenu,
			'core-error-handler': Core.cErrorHandler,
			'core-alert': Core.cAlert,
			'client-navigation': ClientNav,
			breadcrumbs: Core.cBreadcrumbs
		},

		//  ---------- SETUP ----------
		setup() {
			const DEFAULT_TITLE = document.title;

			// Choose the right design template (default is "default")
			const design = Core.Vue.ref('default');
			const router = Core.VueRouter.useRouter();
			const loading = Core.Vue.ref(true);
			const isLoggedIn = Core.Vue.ref(false);

			Core.Vue.onBeforeMount(() => {
				MaintenanceMode.loadScript();
				MaintenanceMode.startGuardTimer();
			});

			// set specific business ID as initially selected
			if (Core.Hash.get('switchBusiness')) {
				Store.app.switchBusiness(Core.Hash.get('switchBusiness'), true, false);
			}

			// Router, permissions and data loading
			router.beforeEach(async (to, from, next) => {
				loading.value = true;
				let nextPage;

				await Core.Aws.Auth.loggedIn()
					.then(async () => {
						// Logged in
						// @TODO: Rework how permissions work in the future for temp collabs / auditors.

						await InitialiseData();
						const nextPagePermitted = await RouterPermissions(to);

						// Variable to track logged in status
						isLoggedIn.value = true;

						nextPage = nextPagePermitted; // route's permission

						// First time flow (missing user details)
						if (!MaintenanceMode.isTurnedOn()) {
							nextPage = FlowDetection.firstTime(nextPage); // FTF
						}

						// Deep linking (from localstorage)
						if (Core.Storage.local('deeplinkUrl')) {
							nextPage = Core.Storage.local('deeplinkUrl');
							Core.Storage.local('deeplinkUrl', null);
						}

						// Maintenance
						if (!Store.user.isOmnevue && MaintenanceMode.isTurnedOn()) {
							const resolved = MaintenanceMode.resolvePath(nextPage);
							nextPage = resolved;
						}

						if (nextPage === to.path) {
							design.value = to.meta.design || 'default'; // set template
							next();
						} else {
							// sometimes nextPage is not defined, but router correctly resolved the right path (like 404 functionality)
							if (!nextPage) {
								design.value = to.meta.design || 'default'; // set template
							}

							app.log(
								'ROUTER',
								'LOGGED IN: Router changed the initially requested path',
								to.path
							);
							next(nextPage);
						}

						// resolve advanced backend permissions for this particular user
						// TODO: in the near future (User Permissions System)
					})
					.catch(async () => {
						// Not logged in
						const nextPagePermitted = await RouterPermissions(to);

						design.value = to.meta.design || 'default'; // set template
						if (nextPagePermitted === to.path) {
							next();
						} else {
							app.log(
								'ROUTER',
								'NOT LOGGED IN: Router changed the initially requested path:',
								to.path
							);

							// set storage data for deeplinking
							if (to.path !== '/') {
								Core.Storage.local('deeplinkUrl', to.path);
							}

							next(nextPagePermitted);
						}
					});
			});

			// After router loaded, do tracking and less important stuff
			router.afterEach((to) => {
				loading.value = false;

				Core.Vue.nextTick(() => {
					loading.value = false;
					Core.Tracking.page('client', to.matched[0].path);
					document.title = `${DEFAULT_TITLE} ${
						to.meta.title?.replace('%crumbNameReplace%', '') || ''
					}`; // browser title
				});

				if (Core.Hash.get('switchBusiness')) {
					Core.Hash.set('switchBusiness');
				}
			});

			return { Store, design, router, loading, Core, isLoggedIn };
		}
	};
</script>

<style lang="scss">
	@import '@Core/styles/index';

	@include block('Client') {
		@include element('lang') {
			float: right;
		}
	}
</style>
