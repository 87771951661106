// IMPORTANT NOTES
// Worth reviewing in the near future as currently we assume all stores are used globally. It makes sense since they data-related, not feature or component based.
// Components REALLY should not have a reason to create their own store, but I might be wrong.
// If we need components-based store, it should likely live with the component (folder), not here, thus it will have different initialisation process (classic Pinia useXXXStore() assigned to a var)

import { useAppStore } from './app.js';
import { useLanguageStore } from './language.js';
import { useBusinessStore } from './business.js';
import { useProductStore } from './product/index.js';
// import { useVueCo2eStore } from './product/vueco2e.js';
// import { useVueEsgStore } from './product/vueesg.js';
import { useUserStore } from './user.js';
import { useMetricStore } from './metric/index.js';

// define dummy objects, as we need to export "something"
let app = {},
	language = {},
	business = {},
	product = {},
	user = {},
	metric = {};

/**
 * init function for our storage to initialise all storage Pinia storage.
 * I had to do it through init, which is fired just after we create Core.VueApp, but right before we actually mount the Vue app in index.js.
 * Timing here is actually important.
 * @namespace Client_Store
 */
function init() {
	app = useAppStore();
	language = useLanguageStore();
	language.update();
	business = useBusinessStore();
	product = useProductStore();
	// product.vueco2e = useVueCo2eStore(); // extends product
	//product.vueesg = useVueEsgStore(); // extends product
	user = useUserStore();
	metric = useMetricStore();
}

export { init, language, business, product, user, app, metric };
