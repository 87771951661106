<template>
	<div class="container DesignDefault">
		<div class="row">
			<core-header class="col-24">
				<slot name="header" />
			</core-header>
		</div>

		<div class="row DesignDefault--wrapperRow">
			<div
				ref="navigation"
				class="d-none d-md-block col-md-7 col-lg-6 DesignDefault--nav">
				<slot name="nav" />
			</div>
			<div class="col-24 col-md-17 col-lg-18">
				<slot name="main" />
			</div>
		</div>

		<div class="row">
			<slot name="footer" />
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Design template for our applications. Default screen without any columns or extra stuff. Just one, main container but with footer and header
	 * @see Events/DESIGN_NAV_TOGGLE
	 * @vue-prop {*} slot slot for your main content or component
	 * @namespace Core_Design_Default
	 */
	import * as Core from '@Core/index.js';

	export default {
		name: 'DesignDefault',

		//  ---------- COMPONENTS ----------
		components: {
			'core-header': Core.cHeader
		},

		//  ---------- SETUP ----------
		setup() {
			const navigation = Core.Vue.ref();

			/**
			 * Capturing global EventBus event name DESIGN_NAV_TOGGLE. Navigation will open the menu overlay for XS and SM views responsive layout
			 * @see Core_Design_Default
			 * @event DESIGN_NAV_TOGGLE
			 */
			Core.Event.on('DESIGN_NAV_TOGGLE', function () {
				navigation.value?.classList.toggle('is-mobileOpen');
			});

			return { navigation };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('DesignDefault') {
		height: 100vh;

		@include element('wrapperRow') {
			min-height: calc(100% - 155px - 100px);
		}

		@include element('main') {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		@include element('nav') {
			&.is-mobileOpen {
				display: block !important;
			}
		}
	}
</style>
