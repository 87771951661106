/**
 @module Core/Tracking
 */

/**
 * This function is used to initialize the Google Analytics tracking code
 * @param {string} googleId - The ID of the Google Analytics tracking code.
 */
export function init(googleId) {
	import(/* @vite-ignore */ `https://www.googletagmanager.com/gtag/js?id=${googleId}`);
	window.dataLayer = window.dataLayer || [];
	window.gtag = function () {
		window.dataLayer.push(arguments);
	};
	window.gtag('js', new Date());
	window.gtag('config', googleId, {
		send_page_view: false // eslint-disable-line camelcase
	});
}

/**
 * Trigger page tracking and set page variable
 * @param {string} appName name of the app as prefix for pagename
 * @param {string} originalPath configured path from router (with params)
 */
export function page(appName, originalPath) {
	try {
		window.gtag('event', 'page_view', {
			page_title: appName + originalPath, // eslint-disable-line camelcase
			page_location: `${window.location.protocol}//${window.location.host}${originalPath}` // eslint-disable-line camelcase
		});

		app.log('TRACKING', 'Page', `${name} ${originalPath}`);
	} catch (e) {
		app.log('TRACKING', 'FAILED, Page', name);
	}
}

/**
 * Send a custom event to Google Analytics
 * @param {string} name The name of the event - avoid ever duplicating this name - follow category_name_action format
 * @param {string} [label=undefined] extra details about this particular event
 * @param {boolean} [nonInteraction=false] if actions is sent by might be caused by code and it shouldn't affect the bounce rate, set to true
 */
export function event(name, label, nonInteraction = false) {
	try {
		window.gtag('event', name, {
			event_category: 'engagement', // eslint-disable-line camelcase
			non_interaction: nonInteraction, // eslint-disable-line camelcase
			event_label: label // eslint-disable-line camelcase
		});
		app.log('TRACKING', `Event: ${name}`, label);
	} catch (e) {
		app.log('TRACKING', `Event Failed: ${name}`, label);
	}
}

/**
 * Send a click event to Google Analytics
 * @param {string} name - The name of the event.
 * @param {string} [label=undefined] extra details about this particular event
 */
export function click(name, label) {
	try {
		window.gtag('event', name, {
			event_category: 'click', // eslint-disable-line camelcase
			event_label: label // eslint-disable-line camelcase
		});
		app.log('TRACKING', `Click: ${name}`, label);
	} catch (e) {
		app.log('TRACKING', `Click Failed: ${name}`, label);
	}
}
