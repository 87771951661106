<template>
	<div
		class="Header"
		data-testid="core-header-component">
		<div class="row align-items-center">
			<div
				v-if="showBurger"
				class="col-2 d-md-none"
				data-testid="core-header-burger"
				@click="
					Core.Event.trigger('DESIGN_NAV_TOGGLE');
					toggle = !toggle;
				">
				<transition-group name="fade">
					<div
						v-if="!toggle"
						class="iconFont-burgermenu Header--burger"
						data-testid="core-header-burger-open"></div>
					<div
						v-else
						class="iconFont-plus Header--burger Header--burger-close"
						data-testid="core-header-burger-close"></div>
				</transition-group>
			</div>
			<div class="col-8 col-sm-8 col-md-7 col-lg-6 Text-center">
				<img
					src="@Core/assets/logo.svg?url"
					alt="Omnevue"
					class="Header--logo"
					data-testid="core-header-logo"
					@click="$router.push('/')" />
			</div>
			<div
				class="col-14 col-sm-14 col-md-17 col-lg-18 align-self-center"
				:class="!showBurger && 'col-17 col-sm-17'">
				<slot />
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Header Component
	 * @vue-prop {*} slot:unnamed slot for your main content or component
	 * @namespace Core_Header
	 */

	import * as Core from '@Core/index.js';

	export default {
		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: name of product
			 * @namespace Core_Header
			 * @property {boolean} [showBurger=true] of product
			 */
			showBurger: {
				required: false,
				type: Boolean,
				default: true
			}
		},

		//  ---------- SETUP ----------
		setup() {
			const toggle = Core.Vue.ref(false);
			return { Core, toggle };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('Header') {
		padding-bottom: var(--space-double);
		padding-top: var(--space-double);

		@media (min-width: $grid-breakpoint-md) {
			height: 125px;
		}

		@include element('logo') {
			width: 200px;
			cursor: pointer;
		}

		@include element('burger') {
			font-size: 30px !important;
			line-height: 30px;
			width: 30px;
			height: 30px;
			position: absolute;
			margin-top: -16px;

			@include modifier('close') {
				transform: rotate(-45deg);
			}
		}
	}
</style>
