/**
 @module Client/MaintenanceMode
 */

import * as Core from '@Core/index.js';
import * as Store from '@Client/store/index.js';
import router from '@Client/router/index.built.js';

const MAINTENANCE_LOAD_INTERVAL = 1000 * 60; // How often will maintenance mode script load
let currentMaintenance = null;
let maintenanceInterval = null;

/* ---------------------------------- PUBLIC METHODS ------------------------------------------ */
/**
 * Tries to load maintenance script and set current maintenance variable
 * @returns {object|null} - resolved current maintenance
 */
export async function loadScript() {
	try {
		const randomId = Core.Utils.makeUuid();
		await Core.Utils.injectScript(`/maintenance.js?id=${randomId}`, 'maintenanceMode', false);

		if (window.maintenance?.description) {
			currentMaintenance = { ...window.maintenance };
			app.log('APP', 'Maintenance Mode is on! Message:', currentMaintenance.description);

			// Show alert for Omnevue people
			if (Store.user.isOmnevue) {
				Core.Event.trigger('ALERT', {
					type: 'danger',
					content: 'The site is in maintenance mode!',
					icon: 'settings'
				});
			}
		} else {
			currentMaintenance = null;
		}

		// Remove script as it's not needed
		document.getElementById('maintenanceMode')?.remove();
		return currentMaintenance;
	} catch (error) {
		// do nothing
	}
}

/**
 * Resolves next path by checking maintenance mode, used for route guard purposes
 * @param  {string} currentPath - currently active router path
 * @returns {string} - next path
 */
export function resolvePath(currentPath) {
	if (isTurnedOn() && currentPath !== '/maintenance-mode') {
		return '/maintenance-mode';
	} else if (!isTurnedOn() && currentPath === '/maintenance-mode') {
		// End of maintenance mode, return to homepage
		return '/';
	}

	// When maintenance mode is on and user already on maintenance mode page
	return currentPath;
}

/**
 * Starts interval that will re-load maintenance mode
 */
export function startGuardTimer() {
	if (!maintenanceInterval) {
		const handleInterval = async () => {
			await loadScript();
			routerGuard();
		};

		app.log('APP', 'Starting maintenance check timer');
		maintenanceInterval = setInterval(handleInterval, MAINTENANCE_LOAD_INTERVAL);
	}
}

/**
 * Uses router to redirect into maintenance-mode when mode has changed
 */
export function routerGuard() {
	const isMaintenancePage = router.currentRoute.value.path === '/maintenance-mode';

	if (isTurnedOn() && !isMaintenancePage && !Store.user.isOmnevue) {
		router.push('/maintenance-mode');
	} else if (isMaintenancePage && !isTurnedOn()) {
		router.push('/');
	}
}

/**
 * Returns whenever maintenance mode is on
 * @returns {boolean} is maintenance mode on
 */
export function isTurnedOn() {
	return !!currentMaintenance;
}

/**
 * Returns maintenance mode message (if set)
 * @returns {string} maintenance description message or empty string if not set
 */
export function getMessage() {
	return isTurnedOn() ? currentMaintenance.message : '';
}
