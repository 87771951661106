import * as Core from '@Core/index.js';
import { useAppStore } from './app.js';

/**
 * @exports Client_Store_Business
 * @namespace Client_Store_Business
 */

// @TODO we can probably remove nullifyOptionalBusinessFields as this shouldn't be here.
// might require update on API side or within the blueprint input component for empty fields to return optionally "null"
// add getStatus methods like we did in user.store to return all key permissions and stuff about business.(not sure if that will be needed, actually)

export const useBusinessStore = Core.Pinia.defineStore({
	id: 'business',
	state: () => ({
		data: { business: {}, loading: { invoices: false } }
	}),

	actions: {
		/**
		 * Fetch businesses data from the API (user has to be logged in).
		 * It will set all store data.
		 * @async
		 * @memberof Client_Store_Business
		 * @returns {object} object from store with all data (businesses)
		 */
		async fetchBusinesses() {
			const response = await Core.Api.get('/businesses');

			// Sort businesses by name
			if (response.body?.length) {
				response.body = response.body.sort((a, b) =>
					a.essential.name.localeCompare(b.essential.name)
				);
			}

			// store some data in "businesses" and the rest is part of the "essential"
			for (const item in response.body) {
				const businessData = response.body[item];
				const businessId = String(businessData.id);

				this.data.business[businessId] = {
					...businessData,
					collaborators: []
				};
			}
		},

		/**
		 * Fetch business information (ID comes from store app)
		 * @memberof Client_Store_Business
		 * @param {string} [id=useAppStore().getSelectedBusiness.id] business ID, current business ID by default
		 * @async
		 * @returns {object} API response same as data in Store
		 */
		async fetchBusiness(id) {
			const businessId = id || useAppStore().getSelectedBusiness.id;

			const saveCurrentRole = this.data.business[businessId]?.role;
			const saveCurrentCompletion = this.data.business[businessId]?.creationCompleted;

			const fetchPromises = [Core.Api.get(`/business/${businessId}`)];

			if (this.isSubsidiary) {
				// Subsidiaries use additional endpoint as they inherit some details from parent
				fetchPromises.push(Core.Api.get(`/business/${businessId}/info`));
			}

			// Fetch data for the business
			const responses = await Promise.all(fetchPromises);

			// Populate data in store
			responses.forEach((response) => {
				// Merge old data with response
				this.data.business[businessId] = {
					...this.data.business[businessId],
					...response.body,
					role: saveCurrentRole,
					creationCompleted: saveCurrentCompletion,
					collaborators: []
				};
			});
		},

		/**
		 * Get all collaborators (only once)
		 * @async
		 * @memberof Client_Store_Business
		 * @returns {Array} of collaborators
		 */
		async fetchCollaborators() {
			const businessId = useAppStore().getSelectedBusiness.id;
			const response = await Core.Api.get(`/business/${businessId}/collaborators`);
			this.data.business[businessId].collaborators = response.body;
		},

		/**
		 * Fetch invoices for current business
		 * @async
		 * @memberof Client_Store_Business
		 * @param {boolean} [force=false] force fetch from API
		 */
		async fetchBusinessInvoices(force = false) {
			const businessId = useAppStore().getSelectedBusiness.id;
			if (!force && this.data.business[businessId].invoices) return;
			this.data.loading.invoices = true;

			try {
				const response = await Core.Api.get(`/business/${businessId}/invoices`);

				if (this.data.business[businessId]) {
					this.data.business[businessId].invoices = response.body.entries;
				}
			} finally {
				this.data.loading.invoices = false;
			}
		},

		/**
		 * This is used only to create subsidiaries
		 * @async
		 * @param {string} [id] business ID of a root.
		 * @param {object} values key-value pairs object for all the data to be pushed to the API
		 * @memberof Client_Store_Business
		 * @returns {object} response object {code, body}
		 */
		async createSubsidiary(id, values) {
			const response = await Core.Api.post(
				`/business/${id}/create-in-group`,
				nullifyOptionalBusinessFields(values)
			);
			await this.fetchBusinesses();
			await this.fetchBusiness(response.id);

			return response;
		},

		/**
		 * Save essential and details about the business or Create New Business
		 * @async
		 * @param {string} [id] business ID has to be provided. If falsy, we will assume we create new business
		 * @param {object} values key-value pairs object for all the data to be pushed to the API
		 * @memberof Client_Store_Business
		 * @returns {object} response object {code, body}
		 */
		async saveInfo(id, values) {
			let response;

			const nullifiedValues = nullifyOptionalBusinessFields(values);

			if (!id) {
				response = await Core.Api.post('/business/create', nullifiedValues);
				await this.fetchBusinesses();
				await this.fetchBusiness(response.body.id);
			} else {
				response = await Core.Api.post(`/business/${id}/info`, nullifiedValues);
				await this.fetchBusiness(response.body.id);
			}

			return response;
		},

		/**
		 * Save one collaborator in API
		 * @async
		 * @param {string} email email address
		 * @memberof Client_Store_Business
		 * @returns {object} response object {code, body}
		 */
		async saveCollaborator(email) {
			const businessId = useAppStore().getSelectedBusiness.id;
			const submitResponse = await Core.Api.post(`/business/${businessId}/collaborator`, {
				email: email
			});

			if (submitResponse.status !== 409) {
				this.fetchCollaborators();
				return true;
			}

			throw submitResponse;
		},

		/**
		 * Delete collaborator based on email
		 * @async
		 * @param {string} email email address
		 * @memberof Client_Store_Business
		 * @returns {object} response object {code, body}
		 */
		async deleteCollaborator(email) {
			const businessId = useAppStore().getSelectedBusiness.id;

			const submitResponse = await Core.Api.post(
				`/business/${businessId}/collaborator/delete`,
				{
					email: email
				}
			);

			if (submitResponse.status === 200) {
				this.fetchCollaborators();
				return true;
			}

			throw submitResponse;
		}
	},

	getters: {
		/**
		 * Return current business
		 * @memberof Client_Store_Business
		 * @param {object} state automatically passed in
		 * @returns {object} with products
		 */
		getCurrentBusiness: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			return state.data.business[businessId];
		},

		/**
		 * Get business by ID
		 * @memberof Client_Store_Business
		 * @param {object} state automatically passed in
		 * @returns {object} with products
		 */
		getBusinessById: (state) => (businessId) => {
			return state.data.business[businessId];
		},

		/**
		 * Get all collaborators for currently selected business
		 * @memberof Client_Store_Business
		 * @param {object} state automatically passed in
		 * @returns {Array} of collaborators
		 */
		getCollaborators: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			return state.data.business[businessId]?.collaborators;
		},
		/**
		 * Get all businesses grouped by business groups
		 * @memberof Client_Store_Business
		 * @param {object} state automatically passed in
		 * @returns {object} Object with root businesses with added property `children` that holds all the children businesses
		 * @example { 'rootId1': { ...businessData, children: [{ ...childBusiness },{ ...childBusiness },{ ...childBusiness }] } }
		 */
		getGroupedBusinesses(state) {
			// Create objects for root businesses
			const rootBusinesses = Object.values(state.data.business).filter(
				({ businessStructure }) => businessStructure === 'ROOT_BUSINESS'
			);

			const groupedStructure = Core.Utils.cloneObject(state.data.business);

			// Add children key to each root business
			rootBusinesses.forEach((business) => {
				groupedStructure[business.id].children = [];
			});

			const subsidiaryBusinesses = Object.values(state.data.business).filter(
				({ businessStructure }) => businessStructure === 'SUBSIDIARY_BUSINESS'
			);

			// Add subsidiaries to root business are remove them from the flat structure
			subsidiaryBusinesses.forEach((subsidiary) => {
				const hasParent = Object.prototype.hasOwnProperty.call(
					groupedStructure,
					subsidiary.businessGroupRoot
				);

				if (hasParent) {
					groupedStructure[subsidiary.businessGroupRoot]?.children?.push(subsidiary);
					delete groupedStructure[subsidiary.id];
				}
			});

			return groupedStructure;
		},

		/**
		 * Get status of the financial year
		 * @memberof Client_Store_Business
		 * @param {object} state data from store
		 * @returns {boolean} true if current financial year is ended for current business
		 */
		getFinancialYearStatus: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			const businessData = state.data.business[businessId];

			if (businessData) {
				const currentDate = new Date();
				const currentMonth = currentDate.getMonth();

				const financialYearEnd = businessData.essential.endOfFinancialYearMonth;
				return currentMonth + 1 > financialYearEnd;
			}
			return false;
		},

		/**
		 * Get all invoices for given business
		 * @memberof Client_Store_Business
		 * @param {object} state automatically passed in
		 * @returns {Array} of invoices
		 */
		getInvoices: (state) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			return state.data.business[businessId]?.invoices || [];
		},

		/**
		 * Get single invoice for given business
		 * @memberof Client_Store_Business
		 * @param {object} state automatically passed in
		 * @returns {object} invoice
		 */
		getInvoice: (state) => (invoiceId) => {
			const businessId = useAppStore().getSelectedBusiness.id;
			const invoice = state.data.business[businessId].invoices?.find(
				(item) => item.cartId === invoiceId
			);
			return invoice;
		},

		/**
		 * Check is business is subsidiary type of business
		 * @returns {boolean} flag if it subsidiary or not
		 */
		isSubsidiary: function () {
			return this.getCurrentBusiness?.businessStructure === 'SUBSIDIARY_BUSINESS';
		},

		/**
		 * Get loading states
		 * @memberof Client_Store_Business
		 * @param {object} state automatically passed in
		 * @returns {object} loading states
		 */
		getLoading: (state) => {
			return state.data.loading;
		}
	}
});

// ----------------------------- PRIVATE METHODS  --------------------------------------
/**
 * Turns optional values into null if they are undefined/empty string
 * @memberof Client_Store_Business
 * @private
 * @param {object} values - business info values (essential, detailed etc)
 * @returns {object} values - new values object with nullified optional fields
 */
function nullifyOptionalBusinessFields(values) {
	const valuesCopy = Core.Utils.mergeDeep(values);

	// Optional fields
	const vat = valuesCopy.detailed?.vat;
	const website = valuesCopy.detailed?.website;

	if (!vat) valuesCopy.detailed.vat = null;
	if (!website) valuesCopy.detailed.website = null;

	return valuesCopy;
}
