import * as Core from '@Core/index.js';
import { useBusinessStore } from './business.js';
import InitialiseData from '@Client/modules/InitialiseData/index.js';

//@TODO this store really is not needed anymore
//the remaining stuff really could belong in Store.Business and we would be done with it.
//needs migration at some point to simplify the app a bit
//just don't add all this content directly into business store file, create submodule or something smart.
//also getSelectedBusiness could be probably split into getter and an action that sets selected.

/**
 * @exports Client_Store_App
 * @namespace Client_Store_App
 */
export const useAppStore = Core.Pinia.defineStore({
	id: 'app',
	state: () => ({
		data: {
			selectedBusiness: { id: '' }
		}
	}),

	actions: {
		/**
		 * Switch currently selected business to a new one
		 * @memberof Client_Store_App
		 * @param {string} id if the business we want to switch to
		 * @param {boolean} [force=false] skip checking if business is in data store
		 * @param {boolean} [initialiseData=true] pull the right data for this business after switch
		 */
		async switchBusiness(id, force = false, initialiseData = true) {
			const businessStore = useBusinessStore();

			// set correct newly selected business
			if (businessStore.getBusinessById(id)) {
				this.data.selectedBusiness = { id: id };
				Core.Storage.local('selectedBusiness', id);
			}

			if (force) {
				Core.Storage.local('selectedBusiness', id);
			}

			if (initialiseData) {
				await InitialiseData(true);
			}
		}
	},

	getters: {
		/**
		 * Figure out the currently selected business (sets from cookie or figures it out from business data)
		 * @memberof Client_Store_App
		 * @param {object} state automatically passed in
		 * @returns {string} currently active business data {id}
		 */
		getSelectedBusiness: (state) => {
			if (state.data.selectedBusiness.id) {
				return state.data.selectedBusiness;
			}

			// check if default business has been already set for state user
			const storedId = Core.Storage.local('selectedBusiness');
			const businessStore = useBusinessStore();

			if (storedId && businessStore.getBusinessById(storedId)) {
				state.switchBusiness(storedId, false, false);
			} else {
				Core.Storage.local('selectedBusiness'); // means selected in cookie was invalid for state login, so let's kill it
				state.switchBusiness(defaultSelectedBusiness(), false, false);
			}

			return state.data.selectedBusiness;
		}
	}
});

// -------------------------------------- PRIVATE METHODS --------------------------------------
/**
 * defaultSelectedBusiness will figure our what default business should be selected in case we don't have idea (or nothing pre-set)
 * @memberof Client_Store_App
 * @private
 * @returns {object} selectedBusiness object {id}
 */
function defaultSelectedBusiness() {
	const businessStore = useBusinessStore();

	// loop through businesses and set one
	let firstBusiness;
	let ownedBusiness;
	for (const item in businessStore.data.business) {
		// first owned and completed
		if (businessStore.data.business[item].role === 'OWNER') {
			// first owned but not completed (FTF needed)
			ownedBusiness = ownedBusiness || item;
			break;
		} else {
			// any non-owned
			firstBusiness = firstBusiness || item;
		}
	}

	return ownedBusiness || firstBusiness;
}
