/**
 * THIS IS A TEMPLATE FILE FOR THE BUILD PROCESS, NOT THE FINAL FILE TO BE USED WITHE THE CODE!
 * The below "marker" will be filled out every time build process runs
 * It is like a pre-processor which will find all *.route files and create dynamic router configuration
 */

import * as Core from '@Core/index.js';

const routes = [
{
path: '/:pathMatch(.*)*',
	component: () => import('../views/404/index.vue'),
	meta: {
		design: 'fullScreen',
		title: '404 Not Found'
	}
},
{
	path: '/business',
	component: () => import('../views/Business/index.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Your Company'
	}
},
{
	path: '/business/switch',
	component: () => import('../views/Business/Switch.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Switch Business'
	}
},
{
	path: '/business/create-new',
	component: () => import('../views/Business/CreateNew.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Add New Business'
	}
},
{
	path: '/business/create-subsidiary',
	component: () => import('../views/Business/CreateSubsidiary.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Add New Subsidiary'
	}
},
{
	path: '/business/collaborators',
	component: () => import('../views/Business/Collaborators.vue'),
	meta: {
		permissions: "loggedIn,isOwner",
		title: 'Manage Collaborators'
	}
},
{
	path: '/business/invoices',
	component: () => import('../views/Business/Invoices.vue'),
	props:true,
	meta: {
		permissions: "loggedIn,isNotSubsidiary",
		title: 'All Invoices'
	}
},
{
	path: '/business/invoices/:paymentId',
	component: () => import('../views/Business/InvoiceDetails.vue'),
	meta: {
		permissions: "loggedIn,isNotSubsidiary",
		title: 'Purchase Invoice'
	}
},
{
	path: '/dashboard',
	component: () => import('../views/Dashboard/index.vue'),
	meta: {
		permissions: "loggedIn",
		title: "Dashboard"
	}
},
{
	path: '/first-time/personal',
	components: {
		default:  () => import('../views/FirstTime/Personal/index.vue')	},
	meta: {
		design: 'single',
		permissions: "loggedIn",
		title: 'Personal Information'
	}
},
{
	path: '/first-time/company-info',
	components: {
		default:  () => import('../views/FirstTime/CompanyInfo/index.vue')	},
	meta: {
		design: 'single',
		permissions: "loggedIn",
		title: 'Company details'
	}
},
{
	path: '/first-time/collaborators',
	components: {
		default:  () => import('../views/FirstTime/Collaborators/index.vue')	},
	meta: {
		design: 'single',
		permissions: "loggedIn",
		title: 'Collaborators'
	}
},
{
	path: '/',
	components: {
		default:  () => import('../views/Home/index.vue')
	},
	meta: {
		design: 'fullScreen',
		permissions: "loggedOut",
		hideHeader: true
	}
},
{
	path: '/integration-complete',
	component: () => import('../views/IntegrationComplete/index.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Integration Complete'
	}
},{
	path: '/maintenance-mode',
	component: () => import('../views/Maintenance/index.vue'),
	meta: {
		design: 'fullScreen',
		title: 'Maintenance'
	}
},
{
	path: '/purchase',
	component: () => import('../views/Purchase/index.vue'),
	meta: {
		permissions: "loggedIn,isNotSubsidiary",
		title: 'Buy And Upgrade',
		design: 'noNav'
	}
},
{
	path: '/purchase/upgrade-product',
	component: () => import('../views/Purchase/UpgradeProduct.vue'),
	meta: {
		permissions: "loggedIn,isNotSubsidiary",
		title: 'Upgrade to vueesgPlus',
		design: 'noNav'
	}
},{
	path: '/reports',
	component: () => import('../views/Reports/index.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Reports'
	}
},
{
	path: '/reports/audit-mark/:productId',
	component: () => import('../views/Reports/AuditMark/index.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'AuditMark™ & Assurance report FY%crumbNameReplace%'
	}
},
{
	path: '/reports/metrics/:productId',
	component: () => import('../views/Reports/Metrics/index.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Report %crumbNameReplace%'
	}
},
{
	path: '/reports/metrics/:productId/esg-summary',
	component: () => import('../views/Reports/Metrics/EsgSummary.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		 title: 'ESG Summary'
	}
},
{
	path: '/reports/metrics/:productId/energy',
	component: () => import('../views/Reports/Metrics/Energy.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		 title: 'Energy'
	}
},
{
	path: '/reports/metrics/:productId/co2',
	component: () => import('../views/Reports/Metrics/Co2.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		 title: 'CO2e Emissions'
	}
},
{
	path: '/reports/metrics/:productId/water',
	component: () => import('../views/Reports/Metrics/Water.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Water'
	}
},
{
	path: '/reports/metrics/:productId/waste',
	component: () => import('../views/Reports/Metrics/Waste.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Waste'
	}
},
{
	path: '/reports/metrics/:productId/vehicle-fleet',
	component: () => import('../views/Reports/Metrics/VehicleFleet.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Travel'
	}
},
{
	path: '/reports/metrics/:productId/products',
	component: () => import('../views/Reports/Metrics/Products.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Product'
	}
},
{
	path: '/reports/metrics/:productId/supply-chain',
	component: () => import('../views/Reports/Metrics/SupplyChain.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Supply Chain'
	}
},
{
	path: '/reports/metrics/:productId/employee-data',
	component: () => import('../views/Reports/Metrics/EmployeeData.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Employee Data'
	}
},
{
	path: '/reports/metrics/:productId/gender',
	component: () => import('../views/Reports/Metrics/Gender.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Gender Diversity'
	}
},
{
	path: '/reports/metrics/:productId/ethnic',
	component: () => import('../views/Reports/Metrics/Ethnic.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Ethnic diversity'
	}
},
{
	path: '/reports/metrics/:productId/customer-data',
	component: () => import('../views/Reports/Metrics/CustomerData.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Customer Data'
	}
},
{
	path: '/reports/metrics/:productId/board-composition',
	component: () => import('../views/Reports/Metrics/BoardComposition.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Board Composition'
	}
},
{
	path: '/reports/metrics/:productId/interventions',
	component: () => import('../views/Reports/Metrics/Interventions.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Interventions & Policies'
	}
},
{
	path: '/reports/metrics/:productId/pay-gap',
	component: () => import('../views/Reports/Metrics/PayGap.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Pay Gap'
	}
},
{
	path: '/reports/metrics/:productId/facilities',
	component: () => import('../views/Reports/Metrics/Facilities.vue'),
	meta: {
		permissions: "loggedIn,isOwner,hasCompletedEsgProducts",
		title: 'Facilities'
	}
},
{
	path: '/support',
	component: () => import('../views/Support/index.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Support'
	}
},
{
	path: '/support/faq',
	component: () => import('../views/Support/faq.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Frequently Asked Questions'
	}
},
{
	path: '/support/messages',
	component: () => import('../views/Support/messages.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Messages'
	}
},
{
	path: '/user',
	component: () => import('../views/User/index.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Profile Settings'
	}
},
{
	path: '/vueco2e',
	component: () => import('../views/VueCO2e/index.vue'),
	redirect: '/',
	meta: {
		permissions: "loggedIn,isNotSubsidiary",
		title: 'vueco2e'
	}
},
{
	path: '/vueco2e/metrics',
	component: () => import('../views/VueCO2e/Metrics.vue'),
	redirect: '/',
	meta: {
		permissions: "loggedIn,isOwner,isNotSubsidiary",
		title: 'vueco2e Metrics'
	}
},
{
	path: '/vuecarbon',
	component: () => import('../views/VueCarbon/index.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'vuecarbon'
	}
},
{
	path: '/vuecarbon/:productId',
	component: () => import('../views/VueCarbon/indexSingle.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'vuecarbon %crumbNameReplace%'
	}
},
{
	path: '/vuecarbon/:productId/integrations',
	component: () => import('../views/VueCarbon/Integrations.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Accounting'
	}
},
{
	path: '/vuecarbon/:productId/accountancy',
	component: () => import('../views/VueCarbon/Accountancy.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Accounting'
	}
},
{
	path: '/vuecarbon/:productId/facilities',
	component: () => import('../views/VueCarbon/Facilities.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Facilities'
	}
},
{
	path: '/vuecarbon/:productId/logistics',
	component: () => import('../views/VueCarbon/Logistics.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Logistics'
	}
},
{
	path: '/vuecarbon/:productId/products',
	component: () => import('../views/VueCarbon/Products.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Products & Packaging'
	}
},
{
	path: '/vuecarbon/:productId/supply-chain',
	component: () => import('../views/VueCarbon/SupplyChain.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Supply Chain'
	}
},
{
	path: '/vuecarbon/:productId/vehicles',
	component: () => import('../views/VueCarbon/Vehicles.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Vehicles'
	}
},
{
	path: '/vuecarbon/:productId/machinery',
	component: () => import('../views/VueCarbon/Machinery.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Machinery'
	}
},
{
	path: '/vuecarbon/:productId/support',
	component: () => import('../views/VueCarbon/Support.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Supporting information'
	}
},
{
	path: '/vueesg',
	component: () => import('../views/VueEsg/index.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'vueesg'
	}
},
{
	path: '/vueesg/:productId',
	component: () => import('../views/VueEsg/indexSingle.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'vueesg %crumbNameReplace%'
	}
},
{
	path: '/vueesg/:productId/integrations',
	component: () => import('../views/VueEsg/Integrations.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Accounting'
	}
},
{
	path: '/vueesg/:productId/accountancy',
	component: () => import('../views/VueEsg/Accountancy.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Accounting'
	}
},
{
	path: '/vueesg/:productId/customers',
	component: () => import('../views/VueEsg/Customers.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Customer Data'
	}
},
{
	path: '/vueesg/:productId/facilities',
	component: () => import('../views/VueEsg/Facilities.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Facilities'
	}
},
{
	path: '/vueesg/:productId/risk-compliance',
	component: () => import('../views/VueEsg/RiskCompliance.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Tax'
	}
},
{
	path: '/vueesg/:productId/board-composition',
	component: () => import('../views/VueEsg/BoardComposition.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Board Composition'
	}
},
{
	path: '/vueesg/:productId/policies',
	component: () => import('../views/VueEsg/Policies.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Plans & Policies'
	}
},
{
	path: '/vueesg/:productId/hr',
	component: () => import('../views/VueEsg/Hr.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Employee Data'
	}
},
{
	path: '/vueesg/:productId/logistics',
	component: () => import('../views/VueEsg/Logistics.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Logistics'
	}
},
{
	path: '/vueesg/:productId/products',
	component: () => import('../views/VueEsg/Products.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Products & Packaging'
	}
},
{
	path: '/vueesg/:productId/sales',
	component: () => import('../views/VueEsg/Sales.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Sales'
	}
},
{
	path: '/vueesg/:productId/supply-chain',
	component: () => import('../views/VueEsg/SupplyChain.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Supply Chain'
	}
},
{
	path: '/vueesg/:productId/vehicles',
	component: () => import('../views/VueEsg/Vehicles.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Vehicles'
	}
},
{
	path: '/vueesg/:productId/machinery',
	component: () => import('../views/VueEsg/Machinery.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Machinery'
	}
},
{
	path: '/vueesg/:productId/support',
	component: () => import('../views/VueEsg/Support.vue'),
	meta: {
		permissions: "loggedIn",
		title: 'Supporting information'
	}
}

];

const router = Core.VueRouter.createRouter({
	history: Core.VueRouter.createWebHistory(),
	routes,
	scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    } else {
			return { top: 0 };
		}
  }
});

export default router;