import * as Core from '@Core/index.js';
import { METRICS_CONFIG } from './config';

/**
 * @exports Client_Store_Metric
 * @namespace Client_Store_Metric
 */
export const useMetricStore = Core.Pinia.defineStore({
	id: 'metric',
	state: () => ({
		data: {},
		loading: false,
		version: null
	}),

	actions: {
		/**
		 * Fetch metrics for given product IDs and sections
		 * @param {Array} criteriaList list of product IDs and businessIds
		 * @param {Array} [sections=[]] list of sections to fetch - If empty, ALL SECTION WILL BE FETCHED
		 * @param {boolean} force fetch (don't use cache)
		 * @returns {Promise<void>}
		 * @memberof Client_Store_Metric
		 */
		async fetchMetrics(criteriaList, sections = [], force = false) {
			// If no sections are provided, fetch all sections
			let sectionsToFetch = sections;

			if (sectionsToFetch.length === 0) {
				sectionsToFetch = Object.keys(METRICS_CONFIG);
			}

			const datasetsCollection = criteriaList.flatMap(({ productId, businessId }) =>
				sectionsToFetch.map((section) => ({ productId, section, businessId }))
			);

			const datasetsToFetch = force
				? datasetsCollection
				: datasetsCollection.filter(
						({ productId, section }) => !(`${productId}-${section}` in this.data)
					);

			if (datasetsToFetch.length > 0 && !this.loading) {
				this.loading = true;
				let response;

				// Prepare dataset for all products of type assure
				const datasets = datasetsToFetch.map(({ productId, section, businessId }) => ({
					id: `${productId}-${section}`,
					metrics: METRICS_CONFIG[section].metrics,
					dataset: METRICS_CONFIG[section].dataset,
					criteria: {
						productId,
						businessId
					}
				}));

				try {
					//check if we should load DEMO data or API data
					if (criteriaList[0].productId === 'demoData') {
						const demoResponse = await import('../demoData/metric.json');
						response = demoResponse.default;
					} else {
						const apiResponse = await Core.Api.post(`/metrics/datasets`, {
							datasets
						});
						response = apiResponse.body;
					}

					if (response) {
						this.data = {
							...this.data,
							...Object.fromEntries(
								response.map(({ id, headers, data, version }) => [
									id,
									{ headers, data, version }
								])
							)
						};

						// Set the metric version for the current product
						this.version = response[0]?.version;
					}
				} finally {
					this.loading = false;
				}
			} else {
				// If there is data already for multiple products, get the version for the selected product
				const productId = criteriaList[0].productId;
				// Metrics version for the current product
				const version = this.data[`${productId}-assure`]?.version;
				this.version = version;
			}
		}
	},
	getters: {
		/**
		 * Get product data by metric ID
		 * @memberof Client_Store_Metric
		 * @param {object} state automatically passed in
		 * @returns {Function} getter function expecting {string} datasetName, {string} productId, {array} categories, {*} extra
		 */
		getDataByType: (state) => {
			// @TODO: Define what categories and extra are
			return (datasetName, productId, categories, extra) => {
				if (!productId) {
					return {};
				}

				const transformMethod = METRICS_CONFIG[datasetName].method;
				const datasetId = `${productId}-${datasetName}`;

				const data =
					[
						state.data[datasetId]?.data.map((item) => ({
							...item,
							name: item.shortLabel,
							data: item.values
						}))
					] ?? [];

				const sumOfMetrics = data.reduce((acc, yearData) => yearData?.length + acc, 0);
				const isEmpty = sumOfMetrics === 0;

				if (isEmpty) return { series: [], categories };
				if (!transformMethod) return { series: [], categories };

				return transformMethod(data, categories, extra);
			};
		},
		/**
		 * Get tabular data
		 * @memberof Client_Store_Metric
		 * @param {object} state automatically passed in
		 * @returns {Function} getter function expecting {string} datasetName, {string} productId
		 */
		getTabularData: (state) => {
			return (datasetName, productId) => ({
				headers: state.data[`${productId}-${datasetName}`]?.headers ?? [],
				data: state.data[`${productId}-${datasetName}`]?.data ?? []
			});
		},

		/**
		 * Get tabular data flipping the orientation
		 * @memberof Client_Store_Metric
		 * @param {object} state automatically passed in
		 * @returns {Function} getter function expecting {string} datasetName, {string} productId
		 */
		getFlippedTabularData: (state) => {
			return (datasetName, productId) => {
				const dataset = state.data[`${productId}-${datasetName}`];
				return {
					headers: dataset.data?.map((item) => item.shortLabel) ?? [],
					data:
						dataset.headers?.map((header, index) => ({
							shortLabel: header,
							values: dataset.data?.map((item) => item.values[index]),
							renderedValues: dataset?.data.map((item) => item.renderedValues[index])
						})) ?? []
				};
			};
		}
	}
});
