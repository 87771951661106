<template>
	<div class="row Breadcrumbs Space-bottomDouble">
		<div class="col-18">
			<h1 class="Text-h1 Space-bottomNone">
				<span class="Color-secondary">{{ state.title1 }}&nbsp;</span>
				<span
					v-if="state.title2"
					class="Color-tertiary">
					{{ state.title2 }}
				</span>
			</h1>
			<ul
				v-if="breadcrumbs"
				class="Space-leftNone">
				<li
					v-for="(crumb, index) in state.breadcrumbs"
					:key="index"
					class="d-inline">
					<router-link
						v-if="index < state.breadcrumbs.length - 1"
						class="Text-link Text-small"
						:to="crumb.link"
						@click="Core.Tracking.click(`breadcrumbs-link-${index}`)">
						{{ crumb.title }}
					</router-link>
					<span
						v-else
						class="Text-small">
						{{ crumb.title }}
					</span>

					<span v-if="index < state.breadcrumbs.length - 1"> &gt; </span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';

	export default {
		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: breadcrumbs enabled or disabled flag
			 * @namespace Core_Breadcrumbs
			 * @property {boolean} [breadcrumbs=true] breadcrumbs enabled or disabled flag
			 */
			breadcrumbs: {
				required: false,
				type: Boolean,
				default: true
			},

			/**
			 * PROP: crumbNameReplace custom string that will be used to put in designed place in route.meta.breadcrumb
			 * @namespace Core_Breadcrumbs
			 * @property {string | number} [crumbNameReplace=string|number] crumbNameReplace custom string that will be used to put in designed place in route.meta.breadcrumb
			 */
			crumbNameReplace: {
				required: false,
				type: [String, Number],
				default: ''
			},
			/**
			 * PROP: firstRoute - which is the first route of the app, the HOMEPAGE -> e.x. client-dashboard, devplatform - home
			 * @namespace Core_Breadcrumbs
			 * @property {string } [firstRoute='dashboard'] which is the first route of the app, the HOMEPAGE -> e.x. client-dashboard, devplatform - home
			 */
			firstRoute: {
				required: false,
				type: String,
				default: 'dashboard'
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const route = Core.VueRouter.useRoute();
			const router = Core.VueRouter.useRouter();

			const state = Core.Vue.reactive({
				title1: null,
				title2: null,
				breadcrumbs: [],
				loggedIn: false
			});

			// Initialise data for this component
			Core.Vue.onBeforeMount(() => {
				Core.Aws.Auth.loggedIn().then(() => {
					state.loggedIn = true;
				});

				// create two-var title
				let titleItems =
					route.meta.breadcrumb?.replace('%crumbNameReplace%', props.crumbNameReplace) ||
					route.meta.title;
				titleItems = titleItems.split(' ');
				state.title1 = titleItems.shift();
				state.title2 = titleItems.join(' ');

				// figure our the breadcrumbs elements
				if (props.breadcrumbs) {
					state.breadcrumbs = buildBreadcrumbs(router.getRoutes(), route.path);
				}
			});

			// --------- EVENTS --------------------
			/**
			 * Global Event.trigger() event name 'BREADCRUMBS-REPLACE' to replace in breadcrumbs dynamically title & crumb where applicable using %crumbNameReplace% placeholder
			 * @event BREADCRUMBS-REPLACE
			 * @see Core_Breadcrumbs
			 * @param {string} name - target value to replace placeholder with
			 */
			Core.Event.on('BREADCRUMBS-REPLACE', function (name, data) {
				for (const item in state.breadcrumbs) {
					state.breadcrumbs[item].title = state.breadcrumbs[item].title.replace(
						'%crumbNameReplace%',
						data
					);
				}

				state.title1 = state.title1.replace('%crumbNameReplace%', data);
				state.title2 = state.title2.replace('%crumbNameReplace%', data);
			});

			// --------- PRIVATE METHODS --------------------
			/**
			 * Recursive method to go through all paths in the url and figure out 'natural' breadcrumbs based on router configuration
			 * @namespace Core_Breadcrumbs
			 * @private
			 * @param {Array} routes from the vue router
			 * @param {Array | string} startPaths current path or exploded path to array
			 * @param {Array} [existingPaths=[]] array with all crumbs
			 * @returns {Array} of final crumbs
			 */
			function buildBreadcrumbs(routes, startPaths, existingPaths = []) {
				const paths = Array.isArray(startPaths) ? startPaths : startPaths.split('/');
				let pathToCheck = paths.join('/') || '/';

				// overwrite of the top level path as it's login screen, not actually a dashboard page
				if (pathToCheck === '/') {
					pathToCheck = `/${props.firstRoute}`;
				}

				// replace all dynamic parts of the url with params provided in the given url (usually it is once, but already built to handle multiple)
				const matched = routes.filter((currentRoute) => {
					const replacedRouterPath = pathParamsReplacer(currentRoute.path);
					return replacedRouterPath === pathToCheck;
				})[0];

				if (matched && matched.meta.title) {
					existingPaths.unshift({
						title:
							matched.meta.breadcrumb?.replace(
								'%crumbNameReplace%',
								props.crumbNameReplace
							) || matched.meta.title,
						link: pathParamsReplacer(matched.path)
					});
				}

				paths.pop(); // drops last item

				if (paths.length > 0) {
					return buildBreadcrumbs(routes, paths, existingPaths);
				}

				return existingPaths;
			}

			/**
			 * Replaces all dynamic elements of the path with correct values
			 * @namespace Core_Breadcrumbs
			 * @private
			 * @param {string} path from the vue router
			 * @returns {string} with final replaced path
			 */
			function pathParamsReplacer(path) {
				const currentParams = Core.VueRouter.useRoute().params;
				let replacedRouterPath = String(path);

				for (const param in currentParams) {
					replacedRouterPath = replacedRouterPath.replace(
						`:${param}`,
						currentParams[param]
					);
				}

				return replacedRouterPath;
			}

			return { Core, state, route };
		}
	};
</script>

<style lang="scss">
	@include block('Breadcrumbs') {
		height: 75px;
	}
</style>
