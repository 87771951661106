<template>
	<div
		class="Navigation align-self-stretch"
		data-testid="core-navigation">
		<!-- Main navigation -->
		<div class="row Box Box-dark">
			<template
				v-for="item in configuration"
				:key="item.name">
				<component
					:is="item.disabled ? 'div' : 'router-link'"
					v-if="!item.hidden"
					:to="item.link"
					class="Navigation--item"
					:class="{ 'is-disabled': item.disabled }"
					data-testid="core-navigation-item"
					@click="Core.Tracking.click('navigation-link')">
					<div
						v-if="item.icon"
						class="Navigation--icon"
						:class="item.icon"></div>
					{{ item.name }}
				</component>

				<!-- Subitems -->
				<template
					v-for="subItem in item.subItems"
					:key="subItem.name">
					<component
						:is="subItem.disabled ? 'div' : 'router-link'"
						v-if="!item.hidden && !subItem.hidden"
						:to="subItem.link"
						class="Navigation--item Navigation--item-child"
						:class="{ 'is-disabled': subItem.disabled }"
						data-testid="core-navigation-item-child"
						@click="Core.Tracking.click('navigation-subLink')">
						<div
							class="Navigation--icon Navigation--icon-child"
							:class="subItem.icon || 'iconFont-chevron rotate-270'"></div>
						{{ subItem.name }}
						<span
							v-if="subItem.notification"
							class="Navigation--notification"
							>{{ subItem.notification }}</span
						>
					</component>
				</template>
			</template>
		</div>

		<!-- Event based additional component -->
		<component
			:is="subItemComp"
			v-bind="subItemCompProp" />
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'Navigation',

		/**
		 * @typedef {object} MenuItem
		 * @property {boolean} [disabled=false] - Whether the menu item is disabled.
		 * @property {boolean} [hidden=false] - Whether the menu item is hidden.
		 * @property {string} link - The link associated with the menu item.
		 * @property {string} name - Displayed text
		 * @property {string} [icon] - icon
		 * @property {MenuItem[]} [subItems] - An array of subitems (if any).
		 */

		//  ---------- PROPS ----------
		props: {
			/**
			 * PROP: pass configuration array for the left-nav menu, it will be passed through
			 * @namespace Core_Navigation
			 * @property {MenuItem[]} config pass configuration array for the left-nav menu, it will be passed through
			 */
			config: {
				type: Array,
				required: true
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const configuration = Core.Vue.computed(() => {
				return {
					...props.config // make config prop reacive!
				};
			});

			const subItemComp = Core.Vue.shallowRef();
			const subItemCompProp = Core.Vue.shallowRef();

			Core.Vue.onBeforeMount(() => {
				/**
				 * Capturing global EventBus event name NAVIGATION_SUBITEM. It will set the passed in component and render it under the navigation
				 * @event NAVIGATION_SUBITEM
				 * @param {object} component provide defineAsyncComponent() imported component to be displayed under the menu.
				 * 	data property can be component or object with {component, props} to be passed to the component
				 */
				Core.Event.on('NAVIGATION_SUBITEM', function (_name, data) {
					if (typeof data.__asyncLoader === 'function') {
						subItemComp.value = data;
					} else {
						subItemComp.value = data.component;
						subItemCompProp.value = data.props;
					}
				});
			});

			return { Core, configuration, subItemComp, subItemCompProp };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('Navigation') {
		height: 100%;
		@include element('backItem') {
			line-height: 1.4em !important;
		}

		@include element('backIcon') {
			transform: rotate(90deg);
			font-size: 16px !important;
			margin-top: 2px !important;
		}

		@include element('item') {
			cursor: pointer;
			display: block;
			font-weight: var(--weight-bold);
			line-height: 60px;
			text-decoration: none;
			color: var(--color-grey800);
			margin-left: -15px;
			padding-left: 25px;
			border-left: 5px solid rgba(0, 0, 0, 0);

			&:is(a) {
				&.router-link-exact-active,
				&:hover,
				&:focus {
					color: var(--color-quaternary);
					border-color: currentColor;
				}
			}

			&.is-disabled {
				color: var(--color-grey500);
				cursor: default;
			}

			@include modifier('child') {
				line-height: 40px;
				color: var(--color-grey700);
				padding-left: 55px;
			}
		}

		@include element('icon') {
			display: inline-block;
			font-size: 24px;
			line-height: 24px;
			margin-right: 10px;
			margin-top: 17px;
			vertical-align: top;
			width: 24px;

			@include modifier('child') {
				font-size: 16px;
				margin-top: 7px;
				width: 16px;
			}
		}

		/* SECONDARY FLOATING NAVIGATION IN THE MENU */
		@include element('second') {
			position: sticky;
			top: var(--space-double);
		}
		@include element('secondNavLink') {
			color: var(--color-grey300);
			font-size: var(--font-size-regular);
			text-decoration: none;
			padding: 8px;
			margin-left: -30px;
			margin-right: -30px;
			cursor: pointer;
			&:hover,
			&:focus {
				color: var(--color-grey300);
				background-color: var(--color-quaternary);
			}
			&.router-link-exact-active {
				color: var(--color-secondary);
				background-color: var(--color-quaternary);
				cursor: default;
			}
		}
		@include element('secondIcon') {
			color: var(--color-secondary);
			font-size: var(--space-single);
			transform: rotate(-90deg);
			display: flex;
			place-items: center;
		}

		@include element('notification') {
			background-color: var(--color-tertiary);
			color: var(--color-greyStart);
			display: inline-block;
			padding-top: 2px;
			padding-bottom: 1px;
			border-radius: 50%;
			min-width: 20px;
			text-align: center;
			font-size: var(--text-tiny);
		}
	}
</style>
