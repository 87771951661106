<template>
	<div class="container DesignNoNav">
		<div class="row">
			<core-header class="col-24">
				<slot name="header" />
			</core-header>
		</div>

		<div class="row justify-content-center DesignNoNav--wrapperRow">
			<div class="col-24 col-md-24 col-sm-24 col-lg-22">
				<slot name="main" />
			</div>
		</div>

		<div class="row">
			<slot name="footer" />
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Design template for our applications. Default screen without any columns or extra stuff. Just one, main container but with footer and header
	 * @see Events/DESIGN_NAV_TOGGLE
	 * @vue-prop {*} slot slot for your main content or component
	 * @namespace Core_Design_DesignNoNav
	 */
	import * as Core from '@Core/index.js';

	export default {
		name: 'DesignNoNav',

		//  ---------- COMPONENTS ----------
		components: {
			'core-header': Core.cHeader
		},

		//  ---------- SETUP ----------
		setup() {
			return {};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('DesignNoNav') {
		height: 100vh;
		@include element('wrapperRow') {
			min-height: calc(100% - 155px - 100px);
		}

		@include element('main') {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
</style>
