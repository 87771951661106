import { useLanguageStore } from '@Core/store/language.js';

// predefined regex configuration for different field types
const REGEX_CONFIGS = {
	email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,50}$/,
	name: /^[A-Za-zÀ-ÿ0-9_. ,'/&+\-\)\(\/]+$/,
	website:
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
	tel: /^\d+$/,
	telCountry: /^[+][0-9]{1,4}$/,
	password: /^(?=.*[a-z]{1}.*)(?=.*[A-Z]{1}.*)(?=.*[0-9]{1}.*)(?=.*[!@#$%^&*]{1}.*)(?:.{8,})$/
};

/**
 * Set error state and custom error message
 * @namespace Core_Blueprint_Helpers
 * @param {string} msg custom error message
 * @param {object} internal object
 * @param {object} state object
 */
function setError(msg, internal, state) {
	internal.currentMsg = state.value.showValidationText && msg;
	internal.currentMsgType = 'error';
}

/**
 * Resolved all correct class names for a form component, based on properties set
 * @namespace Core_Blueprint_Helpers
 * @param {object} properties from the form component (props object)
 * @param {object} internal from the form component (internal object)
 * @returns {string} with the all classes set based on props
 */
export function resolveClassNames(properties, internal) {
	let finalClasses = ' ';
	finalClasses += properties.disabled ? 'is-disabled ' : '';
	finalClasses += properties.icon ? 'is-icon ' : '';
	finalClasses += properties.required ? 'is-required ' : '';
	finalClasses += internal.currentMsgType ? `is-${internal.currentMsgType}` : '';
	return finalClasses;
}

/**
 * Reset all form fields to default state
 * @namespace Core_Blueprint_Helpers
 * @param {object} internal from the form component (internal object)
 * @param {object} state properties object of the form component
 * @param {object} [resetConfig = {value:null, reset:true}] OPTIONAL reset config object
 */
export function resetAll(internal, state, resetConfig = { value: null, reset: true }) {
	internal.currentMsgType = '';
	internal.currentMsg = '';
	resetConfig.reset && (state.value.value = resetConfig.value);
}

/**
 * Validate the field based on provided input data
 * @namespace Core_Blueprint_Helpers
 * @param {object} state state object
 * @param {object} internal internal object
 * @param {object} [elementRef = {}] OPTIONAL element reference
 * @returns {boolean} validation results (it will set msg and msgType too)
 */
export function validators(state, internal, elementRef = {}) {
	const language = useLanguageStore();

	// REQUIRED
	// fail if value is null, undefined or ''
	if (state.value.required) {
		if (
			state.value.value === null ||
			state.value.value === undefined ||
			state.value.value === ''
		) {
			setError(language.string.cForms.missingField, internal, state);
			return false;
		}
	}

	// Check for validity of the checkbox, can be a checkbox or a group of checkboxes (array)
	const checkboxIsInvalid =
		state.value.type === 'checkbox' &&
		(!state.value.value ||
			(Array.isArray(state.value.value) && state.value.value.length === 0));

	// REQUIRED TYPE CHECKBOX
	// fail if required for checkbox and value is not truthy
	// fail if required for checkbox and value is an empty array (nothing is selected from checkbox options)
	if (state.value.required && checkboxIsInvalid) {
		setError(language.string.cForms.missingField, internal, state);
		return false;
	}
	if (state.value.type === 'number') {
		// TYPE NUMBER
		// fail if value is '' (will be if provided string for number type filed)
		// fail if value is bigger than max
		// fail if value is lower than min
		// fail if value does not match step increment
		if (!elementRef.value.validity.valid) {
			setError(language.string.cForms.incorrectValue, internal, state);
			return false;
		}
	}

	// TYPE DATE
	// fail if date is bigger than max date
	// fail if date is smaller than min date
	// fail if date does not match custom date limitation
	if (state.value.type === 'date') {
		if (!elementRef.value.validity.valid) {
			setError(language.string.cForms.wrongFormat, internal, state);
			return false;
		}
	}

	// EMAIL TYPE
	// we need to make sure the value is being updated to lowercase, always.
	if (state.value.type === 'email') {
		state.value.value = state.value.value.toLowerCase();

		if (blackListedEmails(state.value.value)) {
			setError(language.string.cForms.wrongFormat, internal, state);
			return false;
		}
	}

	// REGEX CHECK
	// fail if string doesn't match regex (predefined based on field type or provided in props)
	const validationRegex = REGEX_CONFIGS[state.value.type] || state.value.validation;

	if (validationRegex) {
		const stringValue = String(state.value.value);
		const regExp = new RegExp(validationRegex);

		if (state.value.value && !stringValue.match(regExp)) {
			setError(language.string.cForms.wrongFormat, internal, state);
			return false;
		}
	}

	// MIN LENGTH
	if (state.value.minLength) {
		if (state.value.value && state.value.value.length < state.value.minLength) {
			setError(language.string.cForms.tooShortInput, internal, state);
			return false;
		}
	}

	// Radio component should not be set as 'notification' if value is Boolean of FALSE
	const radioRules =
		state.value.type === 'radio' && state.value.value !== (null || undefined || '');

	// ALL GOOD
	// We set field as "notification" if value has been deleted (it means it was "optional")
	// If value set and all good, set SUCCESS state (except type checkboxes), otherwise NOTIFICATION
	internal.currentMsgType =
		(!state.value.value && radioRules) || state.value.type === 'checkbox'
			? 'notification'
			: 'success';
	internal.currentMsg = '';
	return true;
}

/**
 * Validate the field based on provided input data
 * @param {string} email - email
 * @returns {boolean} validation results
 */
function blackListedEmails(email) {
	const blackList = [
		'a45.in',
		'cachedot.net',
		'manifestgenerator.com',
		'mvrht.com',
		'nonspam.eu',
		'nonspammer.de',
		'spamstack.net',
		'anon.leemail.me',
		'anonymize.com',
		'1usemail.com',
		'fakeinbox.info',
		'mymintinbox.com',
		'mailna.in',
		'fakemail.intimsex.de',
		'govnomail.xyz',
		'anonmail.top',
		'solowtech.com',
		'mailox.biz',
		'1337.no',
		'opayq.com',
		'nowbuzzoff.com',
		'beconfidential.com',
		'dontrackme.com',
		'beconfidential.net',
		'moremobileprivacy.com',
		'blurme.net',
		'ipriva.net',
		'zzrgg.com',
		'celinecityitalia.com',
		'digital10network.com',
		'ivyandmarj.com',
		'freetemporaryemail.com',
		'masudcl.com',
		'yopmail.com'
	];
	const splittedEmail = email.split('@');
	const domain = splittedEmail[1];
	return blackList.includes(domain);
}
