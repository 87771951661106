<template>
	<div class="col-24">
		<div class="Footer">
			<p>
				{{ currentDate.getFullYear() }} ©
				<a
					class="Text-link"
					href="https://www.omnevue.com"
					target="_blank"
					@click="Core.Tracking.click('footer-ommnevue')"
					>ESGgen Limited (Omnevue)</a
				>
				- {{ appDetails.name }} v.<span data-testid="content-version">{{
					appDetails.version
				}}</span>
			</p>
			<p>
				<a
					class="Text-link"
					href="https://www.omnevue.com/privacy-policy/"
					target="_blank"
					@click="Core.Tracking.click('footer-privacy')"
					>{{ Store.language.string.cFooter.privacyTitle }}</a
				>
				|
				<a
					class="Text-link"
					href="https://www.omnevue.com/terms-of-service/"
					target="_blank"
					@click="Core.Tracking.click('footer-terms')"
					>{{ Store.language.string.cFooter.termsTitle }}</a
				>
			</p>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import * as Store from '@Client/store/index.js';

	export default {
		//  ---------- SETUP ----------
		setup() {
			const appDetails = {
				name: BUILD_APP,
				version: BUILD_VERSION
			};

			const currentDate = new Date();

			return {
				Core,
				Store,
				appDetails,
				currentDate
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('Footer') {
		text-align: right;
		color: var(--color-grey400);
		padding-bottom: var(--space-double);
		font-size: var(--text-tiny);

		& a,
		& .Text-link {
			color: var(--color-grey400);
			text-decoration: underline;
		}
	}
</style>
