/**
 @module Client/FlowDetection
 */

import * as Core from '@Core/index.js';
import router from '@Client/router/index.built.js';
import * as Store from '@Client/store/index.js';

/* ---------------------------------- PUBLIC METHODS ------------------------------------------ */
/**
 * It will pull all the initial data, in the right order needed for the user, business and products
 * @param {string} currentPath current path resolved from router, so we can return it if no changes
 * @returns {string} true once resolved. No data
 */
export function firstTime(currentPath) {
	// check professional details (this should also catch collaborators flow)
	const userData = Store.user.getUser;
	for (const item in userData) {
		if (!userData[item] || userData[item] === '') {
			return '/first-time/personal';
		}
	}

	// if business doesn't exist, means it has to be created first!
	if (!Store.app.getSelectedBusiness.id) {
		Store.app.data.hadMissingFtfData = true;
		return '/first-time/company-info';
	}

	// only care about checking business-related stuff if selected business is OWNED by the user
	// if any important data were missing, means they haven't seen guide/collaborators, so show them.
	if (Store.user.isOwner && Store.app.data.hadMissingFtfData) {
		return '/first-time/collaborators';
	}

	// if they have finished personal and business details and wants to go to FTF, redirect to dashboard
	if (
		userData.firstName &&
		Store.business.getCurrentBusiness &&
		currentPath?.indexOf('/first-time') === 0
	) {
		return '/dashboard';
	}

	// default exit page, might be as the last step
	return currentPath;
}

/**
 * ftfNextStep will try to automatically resolve some path from the FTF flow. If nothing, that means we should go to homepage
 * More details here: https://app.clickup.com/20486618/v/dc/kh6eu-2480/kh6eu-5320
 */
export function ftfNextStep() {
	const newPath = firstTime();

	router.push(newPath || Core.AppConfig.config.ROUTER_PERMISSION_REDIRECTS.dashboard);
}
