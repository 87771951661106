/**
 @module Client/InitialiseData
 */

import * as Store from '@Client/store/index.js';
import * as Core from '@Core/index.js';

let initialDataPromise;

/* ---------------------------------- PUBLIC METHODS ------------------------------------------ */
/**
 * It will pull all the initial data, in the right order needed for the user, business and products
 * @param {boolean} [force] new initisialise data and overwrite previous promise.
 * @returns {Promise} true once resolved. No data
 */
export default function (force) {
	// if promise is already in progeess, return it
	if (initialDataPromise && !force) {
		return initialDataPromise;
	}

	// log backend version
	logBackendVersion();

	initialDataPromise = new Promise((resolve) => {
		Core.Store.conversation.disconnect();

		Promise.all([Store.user.fetchData(), Store.business.fetchBusinesses()]).then(() => {
			if (Store.app.getSelectedBusiness.id) {
				Promise.all([
					Core.Pubnub.init(Store.user.getUser.userId, true),
					Store.business.fetchBusiness(),
					Store.product.fetchProducts()
				])
					.then(async () => {
						// download conversations messages (they are used in a few places)
						await Core.Store.conversation.connect(
							`${Store.user.getUser.firstName} ${Store.user.getUser.lastName}`,
							Store.business.getCurrentBusiness.id,
							Store.business.getCurrentBusiness.essential.name
						);
						await Core.Store.conversation.retrieveMessages(null, 1, false); // await to avoid race condition
					})
					.finally(() => {
						resolve();
					});
			} else {
				// no owned businesses, so skipping pulling data and because we didn't set selectedBusiness in Store, it will skip FTF flow
				resolve();
			}
		});
	});

	return initialDataPromise;
}

/**
 * Log backend version to the console
 */
async function logBackendVersion() {
	const backendVersionResponse = await Core.Api.get('/dev/version');
	app.log('CORE', 'Backend Version', backendVersionResponse.body.version);
	app.log('CORE', 'Backend Api Version', backendVersionResponse.body.apiVersion);
}
