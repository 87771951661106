<template>
	<div>
		<div
			class="BpForms BpRadio"
			:class="showValidationStyles && stateComputed.currentClasses"
			:data-testid="id + '-radio'">
			<div
				v-if="state.label"
				class="BpForms--label">
				{{ state.label }}
				<span
					v-if="state.tooltip"
					class="Tooltip Tooltip-bottom"
					tabindex="0"
					:data-tooltip="state.tooltip"
					data-testid="blueprint-radio-tooltip"
					><span class="Tooltip-icon BpForms--tooltip"></span>
				</span>
				<span
					v-if="showValidationStyles"
					class="BpForms--optional"
					>{{ language.string.cForms.optional }}</span
				>
			</div>
			<label
				v-for="(option, index) in state.options"
				:key="option.name"
				class="BpRadio--label"
				:class="option.class"
				tabindex="0"
				@keypress.enter.space.prevent="updateValue(option.value)"
				@click.prevent="updateValue(option.value)">
				<input
					:id="state.id + index"
					class="BpRadio--input"
					:name="state.id"
					:value="option.value"
					:checked="option.value === state.value"
					type="radio"
					:required="state.required"
					:aria-required="state.required"
					data-testid="blueprint-radio-input" />
				<span class="BpRadio--optionName">
					<slot
						name="option"
						:option="option"
						:index="index">
						{{ option.name }}
					</slot>
				</span>
			</label>

			<transition name="slide-down">
				<div
					v-if="internal.currentMsg"
					class="BpForms--msg BpRadio--msg"
					:data-testid="`blueprint-radio-message-type-${msgType}`">
					{{ internal.currentMsg }}
				</div>
			</transition>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import * as Helpers from './helpers.js';

	export default {
		name: 'BlueprintRadio',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Radio
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {Array} options - array of objects (value:String, name:String, class:String)
			 * @namespace Core_Blueprint_Radio
			 * @property {Array} options - array of objects (value:String, name:String, class:String)
			 */
			options: {
				type: Array,
				required: true
			},

			/**
			 * property {string | number | boolean | undefined | null} [value] - bind your value
			 * @namespace Core_Blueprint_Radio
			 * @property {string | number | boolean | undefined | null} [value] - bind your value
			 */
			value: {
				type: [String, Number, Boolean, undefined, null],
				required: false,
				default: null
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_Radio
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {boolean} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Radio
			 * @property {boolean} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {boolean} [disabled=false] - should this field be "disabled"
			 * @namespace Core_Blueprint_Radio
			 * @property {boolean} [disabled=false] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_Radio
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: undefined
			},

			/**
			 * property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 * @namespace Core_Blueprint_Radio
			 * @property {string} [msgType=notification] - type of the message (one of: 'notification', 'warning', 'success', 'error')
			 */
			msgType: {
				type: String,
				required: false,
				validator: function (value) {
					return ['notification', 'warning', 'success', 'error'].includes(value);
				},
				default: 'notification'
			},

			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Radio
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: undefined
			},
			/**
			 * property {Function} [customValidate] - overrides validation function
			 * @namespace Core_Blueprint_Radio
			 * @property {Function} [customValidate] - overrides validation function
			 */
			customValidate: {
				type: Function,
				required: false,
				default: undefined
			},
			/**
			 * property {boolean} [showValidationStyles=true] - do we want to show validation styles or not
			 * @namespace Core_Blueprint_Radio
			 * @property {boolean} [showValidationStyles=true] - do we want to show validation styles or not
			 */
			showValidationStyles: {
				type: Boolean,
				required: false,
				default: true
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();

			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props // make all props reactive
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsg: state.value.msg,
				currentMsgType: state.value.msgType
			});

			// our computed and transformed values to use as we need (from props)
			const stateComputed = Core.Vue.computed(() => {
				return {
					currentClasses: Helpers.resolveClassNames(state.value, internal)
				};
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msg, props.msgType],
				([msgNew, msgTypeNew], [msgPrev, msgTypePrev]) => {
					if (msgNew !== msgPrev) {
						internal.currentMsg = msgNew;
					}
					if (msgTypeNew !== msgTypePrev) {
						internal.currentMsgType = msgTypeNew;
					}
				}
			);

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			/**
			 * updateValue the value from input, validate if needed
			 * only fires if state is not disabled and new value is
			 * @param {string | boolean | number} newValue value selected from the dropdown list to be set
			 */
			function updateValue(newValue) {
				if (!state.value.disabled) {
					context.emit('update:value', newValue);
					Core.Vue.nextTick(() => {
						validate();
					});
				}
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				Helpers.resetAll(internal, state);

				//Let the component where its used to be aware of the reset value
				context.emit('update:value', state.value.value);
			}

			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				// @TODO: Add test for that
				return props.customValidate
					? props.customValidate(state, internal)
					: Helpers.validators(state, internal);
			}

			return { state, stateComputed, internal, language, updateValue, validate, reset };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpRadio') {
		--radio-size: 18px;

		@include element('label') {
			margin-top: 14px;
			margin-bottom: 14px;
			display: flex;
			align-items: center;
			cursor: pointer;
			width: 100%;

			// Circle hover state
			&:hover,
			&:focus {
				.BpRadio--optionName:before {
					outline-width: 6px;
					--shell-color: var(--color-greyEnd);
					--shell-size: 3px;
				}
			}
		}

		@include element('input') {
			display: none;

			// Circle checked state
			&:checked + .BpRadio--optionName:before {
				--shell-color: var(--color-primary);
				--shell-size: 5px !important;
			}
		}

		@include element('optionName') {
			display: flex;
			align-items: center;

			// Radio circle
			// - Outline is hover outer effect
			// - Box-shadow is the shell
			// - Background is inner circle
			&:before {
				--shell-color: var(--color-primary);
				--shell-size: 2px;

				content: '';
				width: var(--radio-size);
				height: var(--radio-size);
				flex: 0 0 auto;
				display: block;
				border-radius: 50%;
				background: #fff;
				transition:
					border-color 0.2s ease,
					box-shadow 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.4),
					outline-width 0.2s ease;
				box-shadow: 0 0 0 var(--shell-size) var(--shell-color) inset;
				outline: solid 0 rgba($color-greyEnd, 0.1);
				margin-right: 8px;
			}

			&.is-disabled:before {
				--shell-color: var(--color-grey700) !important;
			}
		}

		@include element('msg') {
			margin-top: -10px;
		}

		// STATES
		&.is-success {
			.BpRadio--label {
				color: var(--color-stateSuccess);
			}
		}

		&.is-error {
			.BpRadio--label {
				color: var(--color-stateDanger);
			}

			.BpRadio--optionName:before {
				--shell-color: var(--color-stateDanger);
			}
		}

		&.is-warning {
			.BpRadio--label {
				color: var(--color-stateWarning);
			}

			.BpRadio--optionName:before {
				--shell-color: var(--color-stateWarning);
			}
		}

		&.is-disabled {
			.BpRadio--label {
				color: var(--color-grey700) !important;
				cursor: not-allowed;

				.BpRadio--optionName:before {
					--shell-color: var(--color-grey700);
				}

				&:hover,
				&:focus {
					.BpRadio--optionName:before {
						outline-width: 0;
						--shell-color: var(--color-grey700);
						--shell-size: 2px;
					}
				}
			}
		}
	}
</style>
