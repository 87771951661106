<template>
	<div class="progress blue">
		<span class="progress-left">
			<span
				class="progress-bar"
				data-testid="progress-bar"
				:style="getStyle(secondCircle)"></span>
		</span>
		<span class="progress-right">
			<span
				class="progress-bar"
				:style="getStyle(firstCircle)"></span>
		</span>
		<div
			v-if="!icon"
			class="progress-value"
			:style="colors.back">
			{{ Math.floor(progress) }}%
		</div>
		<div
			v-else
			class="progress-value"
			:style="colors.back">
			<span
				class="progress-icon"
				:class="icon"
				data-testid="progress-icon"
				:style="colors.front"></span>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'BlueprintProgressCircle',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {number} [progress=100] - Set the title and it has to be a string
			 * @namespace Core_Blueprint_ProgressCircle
			 * @property {number} [progress=100] - Set the title and it has to be a string
			 */
			progress: {
				type: Number,
				required: false,
				default: 100
			},

			/**
			 * property {string} [icon] - replace value with icon (use iconfont)
			 * @namespace Core_Blueprint_ProgressCircle
			 * @property {string} [icon] - replace value with icon (use iconfont)
			 */
			icon: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [color] - force particular color scheme (danger | warning | success | neutral)
			 * @namespace Core_Blueprint_ProgressCircle
			 * @property {string} [color] - force particular color scheme (danger | warning | success | neutral)
			 */
			color: {
				type: String,
				required: false,
				default: null
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			const colorMap = {
				0: {
					value: 0,
					name: 'red',
					back: 'background: var(--color-stateDangerBg)',
					front: 'border-color: var(--color-stateDanger); color: var(--color-stateDanger)'
				},
				1: {
					value: 25,
					name: 'danger',
					back: 'background: var(--color-stateWarningBg)',
					front: 'border-color: var(--color-stateWarning); color: var(--color-stateWarning)'
				},
				2: {
					value: 50,
					name: 'mixed',
					back: 'background: var(--color-quaternary)',
					front: 'border-color: var(--color-primary); color: var(--color-primary)'
				},
				3: {
					value: 99,
					name: 'success',
					back: 'background: var(--color-stateSuccessBg)',
					front: 'border-color: var(--color-stateSuccess); color: var(--color-stateSuccess)'
				},
				4: {
					value: 101,
					name: 'neutral',
					back: 'background: var(--color-grey900)',
					front: 'border-color: var(--color-grey600); color: var(--color-grey600)'
				}
			};

			const colors = Core.Vue.computed(() => {
				let finalColors = {};

				// force colour
				if (props.color) {
					for (const item in colorMap) {
						if (props.color === colorMap[item].name) {
							return colorMap[item];
						}
					}
				}

				// loop to find the right colours for given % progress
				for (const item in colorMap) {
					if (props.progress >= colorMap[item].value) {
						finalColors = colorMap[item];
					}
				}
				return finalColors;
			});

			// calculate size of the right side of the circle
			const firstCircle = Core.Vue.computed(() => {
				const progress = ((props.progress * 2) / 100) * 180;
				if (progress === 0) return 2.5;
				return progress > 180 ? 180 : progress;
			});

			// calculate size of the left side of the circle
			const secondCircle = Core.Vue.computed(() => {
				const progress = ((props.progress * 2) / 100) * 180 - 180;
				return progress < 0 ? 0 : progress;
			});

			/**
			 * return final inline styles to be applied to progress half-bits
			 * value - first or second circle
			 * @param {number} value calculated number for rotation
			 * @returns {object} concatenated styles to display
			 */
			function getStyle(value) {
				return `transform: rotate(${value}deg); ${colors.value.front}`;
			}

			return { firstCircle, secondCircle, getStyle, colors };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	// TODO: re-do do use block mixins

	.progress {
		width: 150px;
		height: 150px;
		line-height: 150px;
		background: none;
		margin: 0 auto;
		box-shadow: none;
		position: relative;
	}

	.progress:after {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.progress > span {
		width: 50%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;
	}

	.progress .progress-left {
		left: 0;
	}

	.progress .progress-bar {
		width: 100%;
		height: 100%;
		background: none;
		border-width: 8px;
		border-style: solid;
		position: absolute;
		top: 0;
	}

	.progress .progress-left .progress-bar {
		left: 100%;
		border-top-right-radius: 80px;
		border-bottom-right-radius: 80px;
		border-left: 0;
		transform-origin: center left;
	}

	.progress .progress-right {
		right: 0;
	}

	.progress .progress-right .progress-bar {
		left: -100%;
		border-top-left-radius: 80px;
		border-bottom-left-radius: 80px;
		border-right: 0;
		transform-origin: center right;
		animation: loading-1 1.8s linear forwards;
	}

	.progress .progress-value {
		width: 90%;
		height: 90%;
		border-radius: 50%;
		font-size: 24px;
		color: var(--color-greyEnd);
		line-height: 135px;
		text-align: center;
		position: absolute;
		top: 5%;
		left: 5%;
	}

	.progress .progress-icon {
		font-size: 60px;
		color: var(--color-stateSuccess);
		line-height: 135px;
	}

	.progress.blue .progress-bar {
		border-color: var(--color-primary);
	}
</style>
