/**
 * @module Core/Pubnub
 */

import PubNub from 'pubnub';
import { config } from '@Core/modules/AppConfig/index.js';
import * as Api from '@Core/modules/Api/index.js';

let pubnub = null; // PubNub connection class
export { pubnub };

/**
 * Initialise and open connection to pubnub
 * @param {string} userId returned from the backend API /user
 * @param {string} force force to re-initialise the pubnub object
 * @param {string} token returned from our auth api for pubnub (needed for tests, for example)
 */
export async function init(userId, force = false, token) {
	if (!pubnub || force) {
		let accessToken;
		if (!token) {
			const response = await Api.get('/user/pubnub-token');
			accessToken = response.body.token;
		} else {
			accessToken = token;
		}

		// connect
		pubnub = await new PubNub({
			publishKey: config.PUBNUB_P,
			subscribeKey: config.PUBNUB_S,
			userId: userId,
			authKey: accessToken
		});

		setInterval(refreshToken, 14 * 60 * 1000); // every 14 minutes

		app.log('CORE', 'PubNub initialised', pubnub.parseToken(accessToken));
	}
}

/**
 * Disconnects pubnub, needed when switching businesses, for example
 */
export function disconnect() {
	if (pubnub) {
		pubnub.disconnect();
		pubnub = null;
	}
}

/* ---------- PRIVATE METHODS ------------ */
/**
 * Refresh access token
 */
async function refreshToken() {
	const response = await Api.get('/user/pubnub-token');
	const accessToken = response.body.token;

	pubnub.setToken(accessToken);
	app.log('CORE', 'PubNub token refreshed');
}
