/**
 @module Core/Aws
 */

import Amplify from '@aws-amplify/core';
import * as AppConfig from '@Core/modules/AppConfig/index.js';

/**
 * Configure Amplify function that will set all the parameters, environment variables and stuff
 */
export function init() {
	Amplify.configure({
		Auth: {
			region: AppConfig.config.AWS_REGION,
			userPoolId: AppConfig.config.AWS_POOL_ID,
			userPoolWebClientId: AppConfig.config.AWS_CLIENT_ID,
			storage: localStorage,
			oauth: {
				domain: AppConfig.config.AUTH_DOMAIN,
				scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
				redirectSignIn: AppConfig.config.LOGIN_REDIRECT,
				redirectSignOut: AppConfig.config.LOGIN_REDIRECT,
				responseType: 'code'
			}
		}
	});
}

export * as Auth from './Auth.js';
