<template>
	<div class="row PhoneNumber align-item-center">
		<blueprint-select
			id="codeCountry"
			:ref="references.countryCode"
			v-model:value="countryCode"
			class="col-sm-6 col-lg-8"
			type="telCountry"
			:label="phoneNumberLanguage.countryCodeLabel"
			:tooltip="phoneNumberLanguage.countryCodeNote"
			data-testid="phoneNumber-security"
			required
			:max-length="5"
			:options="Core.StaticData.phoneCodes"
			:placeholder="phoneNumberLanguage.countryCodePlaceholder"
			icon="iconFont-phone" />
		<blueprint-input
			id="phoneNumber"
			:ref="references.phoneNumber"
			v-model:value="phoneNumber"
			class="col-sm-10 col-lg-7"
			type="tel"
			data-testid="phoneNumber-security"
			:tooltip="phoneNumberLanguage.phoneUpdateNote"
			required
			:label="phoneNumberLanguage.phoneLabel"
			:max-length="15"
			:placeholder="phoneNumberLanguage.phonePlaceholder"
			icon="iconFont-phone" />
		<p
			class="col-3 align-self-center Space-topDouble Text-center"
			:class="phoneNumberVerified ? 'Color-success' : 'Color-danger'">
			{{ verificationStatus }}
		</p>
		<div class="col-sm-4 col-md-5 col-lg-6 align-self-center">
			<button
				:class="{ 'Btn-loading': loading }"
				class="Btn Btn-outline Btn-formAligned Btn-fullWidth"
				data-testid="userSettings-phoneVer-verifyButton"
				@click="verifyPhoneNumber">
				{{
					phoneNumberVerified
						? phoneNumberLanguage.updateButton
						: phoneNumberLanguage.verifyButton
				}}
			</button>
		</div>

		<one-time-pass
			v-if="otpCodeOpen"
			:field-number="6"
			:no-close="true"
			:code-status="smsCodeSuccessful"
			:code-delivery-destination="`+********${user.phoneNumber.slice(-4)}`"
			data-testid="userSettings-phoneVer-confirmPopup"
			@submit-code="submitPhoneNumberVerification"
			@close-popup="closeOtpInput"
			@resend-code="verifyPhoneNumber" />
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'PhoneNumber',

		components: {
			'one-time-pass': Core.cOneTimePass
		},
		props: {
			/**
			 * User data on which we want MFA
			 * @namespace Core_PhoneNumber
			 * @property {object} user - user data object
			 */
			user: {
				type: Object,
				required: true
			},
			/**
			 * Save user function sent from the parent as we always want to save user info when changing the phone
			 * @namespace Core_PhoneNumber
			 * @property {Function} saveUserFunction - save user data function:
			 */
			saveUserFunction: {
				type: Function,
				required: true
			}
		},

		emits: ['phone-verification'],

		setup(props, context) {
			// Language store
			const language = useLanguageStore();
			const phoneNumberLanguage = language.string.cAccountSecurity;

			// Phone number security and verification variables
			const phoneNumberVerified = Core.Vue.ref(null);
			const otpCodeOpen = Core.Vue.ref(false);
			const smsCodeSuccessful = Core.Vue.ref('');

			// User data objects and variables, saving user tracking user changes etc...
			const phoneNumber = Core.Vue.ref(props.user.phoneNumber);
			const countryCode = Core.Vue.ref(props.user.countryPhonePrefix);
			const userData = Core.Vue.ref({});

			// Loading states, improving UI and UX
			const loading = Core.Vue.ref(false);
			const loadingUserData = Core.Vue.ref(true);

			// References to input elements
			const references = {
				phoneNumber: Core.Vue.ref(),
				countryCode: Core.Vue.ref()
			};

			// convert dynamic User Store userData to internal values
			Core.Vue.onMounted(() => {
				userData.value = Core.Utils.cloneObject(props.user);
			});

			// execute on mount and when the phone number is changed so we can keep the user informed
			Core.Vue.watchEffect(async () => {
				if (props.user.phoneNumber) {
					loadingUserData.value = true;
					const byPassCacheForCognitoUser = true;
					const cognitoUser = await Core.Aws.Auth.getUserData(byPassCacheForCognitoUser);

					phoneNumberVerified.value =
						cognitoUser.attributes.phone_number_verified ?? false;

					loadingUserData.value = false;
				}
			});

			/**
			 * Reset the userData of the otp sms code message input and close it
			 */
			function closeOtpInput() {
				otpCodeOpen.value = false;
				smsCodeSuccessful.value = null;
			}

			/**
			 * validate() public method to validate all fields within this component and return false/true
			 * @namespace Client_Components_UserSettings
			 * @returns {boolean} if fields have been validated or not
			 */
			function validate() {
				return Core.Utils.validateFields(references);
			}

			/**
			 * Verify the phone number via sending SMS code to the user
			 */
			async function verifyPhoneNumber() {
				loading.value = true;

				if (validate()) {
					try {
						await Core.Aws.Auth.setMultiFactorAuthentication('NOMFA');

						// Save user data
						const userDataObject = {
							...userData.value,
							phoneNumber: phoneNumber.value,
							countryPhonePrefix: countryCode.value
						};
						await props.saveUserFunction(userDataObject);

						// Verify phone number through AWS amplify
						await Core.Aws.Auth.manageCurrentUserAttribute('phone_number', 'verify');

						// Open OTP and add code sent to the phone
						otpCodeOpen.value = true;
					} catch (err) {
						Core.Tracking.event('user-phoneVerification-failed', 'on verification');
						Core.Event.trigger('ERROR', {
							type: 'custom',
							title: phoneNumberLanguage.phoneNumberVerificationTitle,
							text: err.message,
							notes: phoneNumberLanguage.phoneNumberVerificationNote,
							icon: 'iconFont-problem',
							btnOk: true,
							btnReport: true
						});
					} finally {
						loading.value = false;
					}
				} else {
					loading.value = false;
					Core.Event.trigger('ERROR', {
						type: 'custom',
						title: phoneNumberLanguage.phoneFieldsNotValidTitle,
						text: phoneNumberLanguage.phoneFieldsNotValidNote,
						icon: 'iconFont-problem',
						btnOk: true
					});
				}
			}

			/**
			 * Submit the verification process for phone number with the code entered via OTP
			 * @param {string} code - The code entered by the user
			 */
			async function submitPhoneNumberVerification(code) {
				loading.value = true;
				Core.Tracking.event('user-phoneVerification-start');
				try {
					await Core.Aws.Auth.manageCurrentUserAttribute('phone_number', 'submit', code);

					if (Core.Storage.session('MFA')) {
						await Core.Aws.Auth.setMultiFactorAuthentication('SMS');
					}

					phoneNumberVerified.value = true;
					smsCodeSuccessful.value = 'success';

					// emit event to track the verification process to talk with sibling components (until backend allows for phoneNumberVerified)
					context.emit('phone-verification', 'success');

					//Verification success happens really fast and its bad UX
					setTimeout(() => {
						otpCodeOpen.value = false;
						smsCodeSuccessful.value = null;
					}, 500);

					Core.Tracking.event('user-phoneVerification-success');
				} catch (err) {
					phoneNumber.value = props.user.phoneNumber;
					countryCode.value = props.user.countryPhonePrefix;
					smsCodeSuccessful.value = 'failed';
					Core.Tracking.event('user-phoneVerification-failed', 'on submit');

					context.emit('phone-verification', 'failed');
				} finally {
					loading.value = false;
				}
			}

			// Phone current verification status (verified, unverified)
			const verificationStatus = Core.Vue.computed(() => {
				const userDataLoadingText = phoneNumberLanguage.loadingLabel;
				const verificationText = phoneNumberVerified.value
					? phoneNumberLanguage.phoneVerified
					: phoneNumberLanguage.phoneUnverified;
				return loadingUserData.value ? userDataLoadingText : verificationText;
			});

			return {
				Core,
				phoneNumberLanguage,
				verificationStatus,
				phoneNumberVerified,
				verifyPhoneNumber,
				submitPhoneNumberVerification,
				otpCodeOpen,
				closeOtpInput,
				smsCodeSuccessful,
				phoneNumber,
				countryCode,
				loading,
				references,
				loadingUserData
			};
		}
	};
</script>
