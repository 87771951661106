<template>
	<div class="container DesignSingle">
		<div class="DesignSingle--header">
			<slot name="header" />
		</div>

		<div class="row justify-content-center align-items-center Space-topDouble">
			<div class="col-sm-24 col-md-18 col-lg-16">
				<div class="row">
					<slot name="main"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Design template for our applications. Single component screen to be used
	 * @vue-prop {*} [slot:header] what to add in the header
	 * @vue-prop {*} slot:main slot to be main content component
	 * @namespace Core_Design_Single
	 */

	export default {
		name: 'DesignSingle',

		//  ---------- SETUP ----------
		setup() {}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('DesignSingle') {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100vh;
		@include element('header') {
			position: absolute;
			top: 0;
			right: var(--space-double);
		}
	}
</style>
