export const CONFIG = {
	// GLOBAL CONFIG
	AWS_REGION: 'eu-west-1',
	MAPS_KEY: 'AIzaSyAzGwLKgLCxxT-Hz9B8BYUtG00Teic26nc',
	FUSE_API_KEY: '-Pv8yoTbqrvf5z5wqx0skw',

	// ADHOC ENVIRONMENTS
	I1: {
		AWS_POOL_ID: 'eu-west-1_bIJtheO75',
		AWS_CLIENT_ID: '13bqmakregnqm69l8uvltpuob5',
		AUTH_DOMAIN: 'i1-esg-portal.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://i1-client.sandbox.omnevue.com',
		CLIENT_API: 'https://i1-client.api.sandbox.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://i1-phorge.sandbox.omnevue.com',
		PHORGE_API: 'https://i1-phorge.api.sandbox.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://i1-developers.sandbox.omnevue.com',
		DEVELOPERS_API: 'https://i1-developers.api.sandbox.omnevue.com',
		STRIPE_KEY:
			'pk_test_51MidZGIOvSJqp49R5xAXD9128RXq08jXTCDTsxBHWYe6AKvOZ2VDF5qW4y3JyhRGAGEXk9IOeZhoihiW49vM7qG900kNZiyilG',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://i1-public.sandbox.omnevue.com'
	},
	I2: {
		AWS_POOL_ID: 'eu-west-1_nQ4Bt5Y8J',
		AWS_CLIENT_ID: '42o0eq8m02agcr05fblqp3ab0c',
		AUTH_DOMAIN: 'i2-esg-portal.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://i2-client.sandbox.omnevue.com',
		CLIENT_API: 'https://i2-client.api.sandbox.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://i2-phorge.sandbox.omnevue.com',
		PHORGE_API: 'https://i2-phorge.api.sandbox.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://i2-developers.sandbox.omnevue.com',
		DEVELOPERS_API: 'https://i2-developers.api.sandbox.omnevue.com',
		STRIPE_KEY:
			'pk_test_51N68bmBAFHTv7Sbid8lAEK8Sf4p8GmsWxN8HLGit37J1H9S58Y5fAopGW6rHhNUCZaV1TvbGqlbZLEbUt13EqrJ400eG6Egh5G',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://i2-public.sandbox.omnevue.com'
	},
	I3: {
		AWS_POOL_ID: 'eu-west-1_XNbtrJONE',
		AWS_CLIENT_ID: '1o7npjhekoo9l4gapgu89i8bko',
		AUTH_DOMAIN: 'i3-esg-portal.auth.eu-west-1.amazoncognito.com',
		DEVELOPERS_AWS_POOL_ID: 'eu-west-1_ETr2gzfRb',
		DEVELOPERS_AWS_CLIENT_ID: 'q2q37l4cfi1i1eaj7am1i0bpu',
		DEVELOPERS_AUTH_DOMAIN: 'https://i3-omnevue-developers.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://i3-client.sandbox.omnevue.com',
		CLIENT_API: 'https://i3-client.api.sandbox.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://i3-phorge.sandbox.omnevue.com',
		PHORGE_API: 'https://i3-phorge.api.sandbox.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://i3-developers.sandbox.omnevue.com',
		DEVELOPERS_API: 'https://i3-developers.api.sandbox.omnevue.com',
		STRIPE_KEY:
			'pk_test_51NNtrjH7KkJqp6wfp8zOV4MCBACPJg8dZsvgCvKyWVb8MnMhJFosmcVCJQTuBKasUlR43mhnGJGuFtGRXxsZLdpf00VecoDGLD',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://i3-public.sandbox.omnevue.com'
	},
	I4: {
		AWS_POOL_ID: 'eu-west-1_z8A0Y4ICq',
		AWS_CLIENT_ID: '6bha0ufovudnqs8elgvu0loaj3',
		AUTH_DOMAIN: 'i4-esg-portal.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://i4-client.sandbox.omnevue.com',
		CLIENT_API: 'https://i4-client.api.sandbox.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://i4-phorge.sandbox.omnevue.com',
		PHORGE_API: 'https://i4-phorge.api.sandbox.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://i4-developers.sandbox.omnevue.com',
		DEVELOPERS_API: 'https://i4-developers.api.sandbox.omnevue.com',
		STRIPE_KEY:
			'pk_test_51N68kXJhvmwF1lCaoovWBmZfLNlkqKaUUjyzB08MBQjdgJyzyCh1wdqgXlo5hMKsBVqbSmNOKndGZnl1wezSiDG500fZaqEW9r',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://i4-public.sandbox.omnevue.com'
	},
	I5: {
		AWS_POOL_ID: 'eu-west-1_TXPFIT3bZ',
		AWS_CLIENT_ID: '4qqshgjolchk008h1i83rs1hbu',
		AUTH_DOMAIN: 'i5-esg-portal.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://i5-client.sandbox.omnevue.com',
		CLIENT_API: 'https://i5-client.api.sandbox.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://i5-phorge.sandbox.omnevue.com',
		PHORGE_API: 'https://i5-phorge.api.sandbox.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://i5-developers.sandbox.omnevue.com',
		DEVELOPERS_API: 'https://i5-developers.api.sandbox.omnevue.com',
		STRIPE_KEY:
			'pk_test_51N68YBAfro3znawLIs8vrtdda27UgxYZvdCDTj4yvYfT6U9TT8KGPcsVvvpObgxUGCZfgvL5zOGyzWDpqxSXy9Q000tUgyY4QI',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://i5-public.sandbox.omnevue.com'
	},

	// NON-PROD
	DEV: {
		AWS_POOL_ID: 'eu-west-1_CP0NCh4gW',
		DEVELOPERS_AWS_POOL_ID: 'eu-west-1_trVQOwM2V',
		DEVELOPERS_AWS_CLIENT_ID: 'pmru3jt0e6tgn5pvjv43lgvm7',
		AWS_CLIENT_ID: '1iror60uakmsltn23bnh40ckqv',
		AUTH_DOMAIN: 'dev-esg-portal.auth.eu-west-1.amazoncognito.com',
		DEVELOPERS_AUTH_DOMAIN: 'https://dev-omnevue-developers.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://dev-client.non-prod.omnevue.com',
		CLIENT_API: 'https://dev-client.api.non-prod.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://dev-phorge.non-prod.omnevue.com',
		PHORGE_API: 'https://dev-phorge.api.non-prod.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://dev-developers.non-prod.omnevue.com',
		DEVELOPERS_API: 'https://dev-developers.api.non-prod.omnevue.com',
		STRIPE_KEY:
			'pk_test_51KxnfsF6VMz9OE7ZmaVNckdzFcae92TGuyIFe09hG7a6qvUyBHRyZcW89KpL8VxWhaZ6XEk0BNaFOOQagYJCk4AS00WWQIsJbX',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://dev-public.non-prod.omnevue.com'
	},
	STAGE: {
		AWS_POOL_ID: 'eu-west-1_MISSkI73m',
		DEVELOPERS_AWS_POOL_ID: 'eu-west-1_06c0GFq4D',
		DEVELOPERS_AWS_CLIENT_ID: '6djuvul75c5ai611adt2e9u04v',
		DEVELOPERS_AUTH_DOMAIN: 'https://stage-omnevue-developers.auth.eu-west-1.amazoncognito.com',
		AWS_CLIENT_ID: '74alipsnj49i4i2ov0a1oqcush',
		AUTH_DOMAIN: 'stage-esg-portal.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://stage-client.non-prod.omnevue.com',
		CLIENT_API: 'https://stage-client.api.non-prod.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://stage-phorge.non-prod.omnevue.com',
		PHORGE_API: 'https://stage-phorge.api.non-prod.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://stage-developers.non-prod.omnevue.com',
		DEVELOPERS_API: 'https://stage-developers.api.non-prod.omnevue.com',
		STRIPE_KEY:
			'pk_test_51KxngPKHytEhnRbLoGTDACW3MAdFT3DIgDNgUx9vOrEy1lE8Omaa1yBbgqy3FVFsFvKWgY9wjZm4uQUlyaZ7H9f900cMCU18Na',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://stage-public.non-prod.omnevue.com'
	},
	SHOWCASE: {
		AWS_POOL_ID: 'eu-west-1_SQtaazZ8N',
		DEVELOPERS_AWS_POOL_ID: 'eu-west-1_ljJeSY4HY',
		DEVELOPERS_AWS_CLIENT_ID: '2s36cvpuua7ddqpcnip66v4l3u',
		AWS_CLIENT_ID: '576cohfrjhf019qckh5jfeo1k7',
		AUTH_DOMAIN: 'showcase-esg-portal.auth.eu-west-1.amazoncognito.com',
		DEVELOPERS_AUTH_DOMAIN: 'https://dummy-showcase.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://showcase-client.non-prod.omnevue.com',
		CLIENT_API: 'https://showcase-client.api.non-prod.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://showcase-phorge.non-prod.omnevue.com',
		PHORGE_API: 'https://showcase-phorge.api.non-prod.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://showcase-developers.non-prod.omnevue.com',
		DEVELOPERS_API: 'https://showcase-developers.api.non-prod.omnevue.com',
		STRIPE_KEY:
			'pk_test_51NDp6SA6lm9EplPMQ00g7TaeARZldQiXZFvQaMiQfcChRj3EG7ygsHDs67A00rOeTPWVEiAK9Csv6HFnL72wJUW300drftCLtc',
		PUBNUB_P: 'pub-c-90af1860-1f3e-44c7-bb5b-f3db8aa9b4d2',
		PUBNUB_S: 'sub-c-d85a89e4-9560-4d1f-be18-718f27e02f94',
		PUBLIC_BUCKET: 'https://showcase-public.non-prod.omnevue.com'
	},

	// PROD
	PROD: {
		AWS_POOL_ID: 'eu-west-1_ebUUIxvOp',
		DEVELOPERS_AWS_POOL_ID: 'eu-west-1_cQISjOYuR',
		AWS_CLIENT_ID: '52vhvh3a8vpmuadmn7aj29ojqn',
		DEVELOPERS_AWS_CLIENT_ID: '4pmfegtkdh117e7sv5hqfqbo04',
		AUTH_DOMAIN: 'production-esg-portal.auth.eu-west-1.amazoncognito.com',
		DEVELOPERS_AUTH_DOMAIN:
			'https://production-developers-portal.auth.eu-west-1.amazoncognito.com',
		CLIENT_LOGIN_REDIRECT: 'https://client.omnevue.com',
		CLIENT_API: 'https://client.api.omnevue.com',
		PHORGE_LOGIN_REDIRECT: 'https://phorge.omnevue.com',
		PHORGE_API: 'https://phorge.api.omnevue.com',
		DEVELOPERS_LOGIN_REDIRECT: 'https://developers.omnevue.com',
		DEVELOPERS_API: 'https://developers.api.omnevue.com',
		STRIPE_KEY:
			'pk_live_51KbQtVKcl7ZSiqi1vVsNFWCchkmZWZ3ZuTiQAdfJYVzaSkoK469UudO9y4P1aMbKmDMkrTUqzyR3LbkWel7MUsjv004IlyRLKe',
		PUBNUB_P: 'pub-c-ee612550-4000-4486-99f1-231e7bdddf76',
		PUBNUB_S: 'sub-c-75d16e9d-719d-4a9f-a83c-acf984898d10',
		PUBLIC_BUCKET: 'https://public.omnevue.com'
	}
};
