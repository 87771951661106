<template>
	<div class="MultiFactorAuth">
		<h1 class="Text-bold Text-h3">{{ mfaLanguage.mfaTitle }}</h1>
		<div class="row justify-content-between">
			<p class="col-lg-20 col-md-18 col-sm-16">
				{{ mfaLanguage.mfaDescription }}
				<span
					class="Tooltip Tooltip-bottom"
					:data-tooltip="mfaLanguage.mfaCautionNote"
					tabindex="0"
					><span class="Tooltip-icon"></span
				></span>
			</p>
			<blueprint-loader
				v-if="loading"
				class="MultiFactorAuth--loader col-lg-4 col-md-4 col-sm-6 Space-bottom Space-right" />
			<blueprint-toggle
				id="toggleGoogleLinking"
				:value="mfaActive"
				:class="{ 'is-visible': !loading }"
				class="MultiFactorAuth--toggle col-lg-2 col-md-4 col-sm-6 Space-bottom Space-right"
				data-testid="userSettings-mfa-toggle"
				@click="toggleMfa" />
		</div>
		<blueprint-popup
			ref="phoneVerificationPopup"
			:header="mfaLanguage.mfaNotSuccessNote"
			size="col-12 offset-7"
			data-testid="userSettings-mfa-enableError">
			<template #default>
				<div class="row justify-content-center Space-bottom">
					<p class="Space-top">
						{{ mfaLanguage.mfaNote }}
					</p>
				</div>
			</template>
		</blueprint-popup>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'MultiFactorAuth',

		props: {
			/**
			 * Phone number verification status
			 * @namespace Core_MultiFactorAuth
			 * @property {string} phoneNumberVerification Phone number verification status
			 */
			phoneNumberVerification: {
				type: String,
				required: false,
				default: ''
			},
			/**
			 * User data on which we want MFA
			 * @namespace Core_MultiFactorAuth
			 * @property {object} user - user data object
			 */
			user: {
				type: Object,
				required: true
			}
		},

		setup(props) {
			const language = useLanguageStore();
			const mfaLanguage = language.string.cAccountSecurity;

			const loading = Core.Vue.ref(false);
			const phoneNumberVerified = Core.Vue.ref(null);
			const phoneVerificationPopup = Core.Vue.ref(null);
			const cognitoUser = Core.Vue.ref(null);
			const mfaActive = Core.Vue.ref(false);

			Core.Vue.watchEffect(async () => {
				if (props.user.phoneNumber && props.phoneNumberVerification !== null) {
					const byPassCacheForCognitoUser = true;

					cognitoUser.value = await Core.Aws.Auth.getUserData(byPassCacheForCognitoUser);

					phoneNumberVerified.value =
						cognitoUser.value.attributes.phone_number_verified ?? false;

					//Check if any kind of MFA is active
					mfaActive.value = cognitoUser.value.preferredMFA !== 'NOMFA';
				}
			});

			/**
			 * Toggles MFA on/off
			 */
			async function toggleMfa() {
				try {
					loading.value = true;
					if (mfaActive.value) {
						await Core.Aws.Auth.setMultiFactorAuthentication('NOMFA');
						mfaActive.value = false;

						Core.Storage.session('MFA', null);

						Core.Tracking.event('user-mfa-deactivated');

						Core.Event.trigger('ALERT', {
							type: 'success',
							content: 'Multi-factor authentication deactivated successfully!',
							icon: 'tick'
						});
					} else {
						if (phoneNumberVerified.value === false) {
							phoneVerificationPopup.value.toggle();
						} else {
							await Core.Aws.Auth.setMultiFactorAuthentication('SMS');
							mfaActive.value = true;

							Core.Storage.session('MFA', 'active');

							Core.Tracking.event('user-mfa-deactivated', 'sms');

							Core.Event.trigger('ALERT', {
								type: 'success',
								content: 'Multi-factor authentication activated successfully!',
								icon: 'tick'
							});
						}
					}
				} catch (error) {
					Core.Event.trigger('ALERT', {
						type: 'error',
						content: 'Request failed, please try again later.',
						icon: 'cross'
					});
				} finally {
					loading.value = false;
				}
			}

			return { toggleMfa, loading, phoneVerificationPopup, mfaActive, mfaLanguage };
		}
	};
</script>

<style lang="scss">
	@include block('MultiFactorAuth') {
		position: relative;
		@include element('loader') {
			position: absolute;
			right: -50px;
			bottom: -40px;
		}
		@include element('toggle') {
			opacity: 0;
			@include state('visible') {
				opacity: 1;
			}
		}
	}
</style>
