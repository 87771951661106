<template>
	<div
		class="ClientNavBusiness"
		data-testid="component-ClientNavBusiness">
		<div class="row Box Box-dark align-items-center">
			<div class="col-19">
				<div class="Text-tiny">
					{{ Store.language.string.cClientNavBusiness.title }}
				</div>
				<div
					class="Tooltip Tooltip-dark Tooltip-bottom Tooltip-noUnderline"
					:data-tooltip="Store.business.getCurrentBusiness?.essential.name">
					<div
						class="ClientNavBusiness--name Space-topNone"
						data-testid="component-ClientNavBusiness-name">
						{{ Store.business.getCurrentBusiness?.essential.name }}
					</div>
				</div>
			</div>
			<div class="col-5 Text-right">
				<router-link
					to="/business/switch"
					class="ClientNavBusiness--button Tooltip Tooltip-dark Tooltip-bottom Tooltip-noUnderline"
					:data-tooltip="Store.language.string.cClientNavBusiness.tooltipSwitch"
					:aria-label="Store.language.string.cClientNavBusiness.tooltipSwitch"
					@click="
						Core.Tracking.click('business-switchBusiness', 'navigation switch button')
					">
					<span class="iconFont-swap"></span>
				</router-link>
			</div>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import * as Store from '@Client/store/index.js';

	export default {
		name: 'ClientNavBusiness',

		//  ---------- SETUP ----------
		setup() {
			return {
				Core,
				Store
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('ClientNavBusiness') {
		position: relative;

		@include element('name') {
			color: var(--color-grey900);
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		@include element('button') {
			color: var(--color-grey700);
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			font-size: var(--size-h4);
			margin-top: 10px;
			cursor: pointer;

			&:hover,
			&:focus {
				color: var(--color-grey900);
			}

			&.Tooltip {
				&:before {
					text-align: center;
				}
			}

			// when route active
			&.router-link-exact-active {
				color: var(--color-primary);
			}

			& > span {
				font-size: var(--text-h3);
			}
		}
	}
</style>
