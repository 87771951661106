<template>
	<transition name="vanish">
		<div
			v-if="showAlert"
			class="BpAlert"
			data-testid="core-alert">
			<div
				class="Callout"
				:class="state.type && 'Callout-' + state.type"
				data-testid="core-alert-content">
				<div
					v-if="state.icon"
					class="BpAlert--icon"
					data-testid="core-alert-icon"
					:class="'iconFont-' + state.icon"></div>
				{{ state.content }}
			</div>
		</div>
	</transition>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'Alert',

		//  ---------- SETUP ----------
		/**
		 * Alert component cannot be used directly. It is embedded within main templates and can be created/shown by Core.Event.trigger() method only.
		 * Check Events/ALERT section in the documentation for details.
		 * Trigger data accept data in specific format only.
		 * @see Events/ALERT
		 * @namespace Core_Alert
		 * @returns {object} Component
		 */
		setup() {
			const showAlert = Core.Vue.ref(false);

			const state = Core.Vue.ref({
				type: null,
				content: '',
				icon: null
			});

			Core.Vue.onBeforeMount(() => {
				/**
				 * Global Event.trigger() event name 'ALERT' to display Alert component that will audomatically disapear.
				 * This is part of Core/Blueprint/Alert component. It requires object with extra data.
				 * @event ALERT
				 * @see Core_Alert
				 * @param {string} [type] - define type (warning|error|success)
				 * @param {string} content - text to display
				 * @param {string} [icon] - icon classname to be added in the alert
				 */
				Core.Event.on('ALERT', function (name, data) {
					state.value = data;
					showAlert.value = true;

					setTimeout(function () {
						showAlert.value = false;
					}, 5000);
				});
			});

			return { state, showAlert };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpAlert') {
		position: fixed;
		width: 350px;
		left: calc(50% - 175px);
		top: var(--space-single);

		@include element(icon) {
			vertical-align: middle;
			display: inline-block;
			margin-bottom: 5px;
			margin-right: 5px;
		}
	}
</style>
