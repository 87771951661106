<template>
	<div class="OneTimePass">
		<blueprint-popup
			ref="otpPopup"
			:header="otpLanguage.otpPopupHeader"
			size="col-lg-12 col-md-12 col-sm-16 offset-lg-6 offset-md-6 offset-sm-4"
			:on-close="clearPopup"
			:no-close="noClose">
			<template #default>
				<div class="row Text-center justify-content-center align-items-center">
					<p class="Space-top Space-bottom">
						{{ otpLanguage.deliveryNote }}
						{{ codeDeliveryDestination }}
					</p>
					<blueprint-input
						v-for="number in fieldNumber"
						:id="`OTP-code${number}`"
						:ref="otpInputRefs[number - 1]"
						:key="number"
						v-model:value="otpCodes[number]"
						text-align="center"
						class="col-3 col-md-4 col-lg-3 col-sm-4 col-xs-4"
						:max-length="1"
						:msg-type="codeStatus === 'failed' ? 'error' : 'notification'"
						:show-validation-text="false"
						required
						@keyup.delete="deleteCurrentInput"
						@paste.prevent="pasteCode" />
					<div class="OneTimePass--icons Space-top Space-bottomDouble">
						<span
							:class="codeStatus === 'success' && 'OneTimePass--showIcon'"
							class="OneTimePass--successIcon col-24 iconFont-simpleTick" />
						<span
							:class="codeStatus === 'failed' && 'OneTimePass--showIcon'"
							class="OneTimePass--failIcon col-24 iconFont-cross" />
					</div>
					<div class="col-24 Space-top Space-bottom">
						<p class="Text-small">
							{{ otpLanguage.resendText }}
							<button
								class="Text-link"
								@click="$emit('resend-code')">
								{{ otpLanguage.resendButtonLink }}
							</button>
						</p>
					</div>
				</div>
			</template>
		</blueprint-popup>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'OneTimePass',

		// ---------------PROPS---------------
		props: {
			/**
			 * PROP: fieldNumber The number of input fields to display
			 * @namespace Client_components_OneTimePass
			 * @property {number} fieldNumber The number of input fields to display
			 */
			fieldNumber: {
				type: Number,
				required: true
			},
			/**
			 * PROP: codeStatus is the code verified or not (correct/incorrect)
			 * @namespace Client_components_OneTimePass
			 * @property {string} [codeStatus = ""] The status of the code
			 */
			codeStatus: {
				type: String,
				required: false,
				default: ''
			},
			/**
			 * PROP: codeDeliveryDestination On what medium do we want the code to be delivered
			 * @namespace Client_components_OneTimePass
			 * @property {string} [codeDeliveryDestination=""] 'email' | 'SMS' | something else
			 */
			codeDeliveryDestination: {
				type: String,
				required: false,
				default: ''
			},

			/**
			 * PROP: noClose Do we want to display the close button
			 * @namespace Client_components_OneTimePass
			 * @property {boolean} [noClose=false] Do we want to display the close button
			 */
			noClose: {
				type: Boolean,
				required: false,
				default: false
			}
		},

		// ----------EMITS----------
		emits: ['submit-code', 'close-popup', 'resend-code'],

		setup(props, context) {
			const language = useLanguageStore();
			const otpLanguage = language.string.cOneTimePass;

			const otpPopup = Core.Vue.ref();
			const otpInputRefs = Array.from({ length: props.fieldNumber }, () =>
				Core.Vue.ref(null)
			);
			const smsCode = Core.Vue.ref('');
			const activeInput = Core.Vue.ref(0);
			const otpCodes = Core.Vue.reactive({});
			const msg = Core.Vue.ref('');

			Core.Vue.onMounted(() => {
				generateOtpCodes(props.fieldNumber);

				otpPopup.value.toggle();
				setTimeout(() => {
					otpInputRefs[0].value[0].inputRef.focus();
				}, 100);
			});

			/**
			 * Generate otp codes
			 * @param {number} count the number of otp codes to generate
			 */
			function generateOtpCodes(count) {
				for (let i = 1; i <= count; i++) {
					otpCodes[i] = '';
				}
			}

			/**
			 * Spread the pasted code in the otp inputs fields
			 * @param {Event} event event object from the paste event
			 */
			function pasteCode(event) {
				const pastedCode = event.clipboardData.getData('text');
				const codeArray = pastedCode.split('');

				if (codeArray.length === props.fieldNumber) {
					codeArray.forEach((code, index) => {
						otpCodes[index + 1] = code;
					});
				}
			}

			/**
			 * Delete the current input value and jump to the previous input
			 */
			function deleteCurrentInput() {
				if (otpCodes[otpInputRefs.length] !== '') return;
				otpInputRefs[activeInput.value - 1].value[0].inputRef.focus();
			}

			/**
			 * Clear the popup and the otp codes, emit closing actions to parent component
			 */
			function clearPopup() {
				Object.keys(otpCodes).forEach((key) => {
					otpCodes[key] = '';
				});

				context.emit('close-popup');
			}

			// watch codes and focus on the next input if the code is filled
			Core.Vue.watch(otpCodes, () => {
				Object.keys(otpCodes)?.forEach((key, index) => {
					if (otpCodes[key].length === 1) {
						if (index < otpInputRefs.length - 1) {
							//Don't jump on next field if user corrects values that are in the middle
							if (otpCodes[otpInputRefs.length] !== '') return;
							otpInputRefs[index + 1].value[0].inputRef.focus();

							//Save the current active input index
							activeInput.value = index + 1;
						} else {
							const values = Object.values(otpCodes);
							smsCode.value = values.join('');

							//Emit the code value to be used in the parent component
							context.emit('submit-code', smsCode.value);
						}
					}
				});
			});

			return {
				otpPopup,
				otpInputRefs,
				otpCodes,
				clearPopup,
				deleteCurrentInput,
				pasteCode,
				msg,
				otpLanguage
			};
		}
	};
</script>

<style lang="scss">
	@include block('OneTimePass') {
		@include element('icons') {
			position: relative;
		}
		@include element('successIcon') {
			color: var(--color-stateSuccess);
			font-size: 2rem;
			opacity: 0;
			position: absolute;
			inset: 0;
		}
		@include element('failIcon') {
			color: var(--color-stateDanger);
			font-size: 2rem;
			opacity: 0;
			position: absolute;
			inset: 0;
		}
		@include element('showIcon') {
			opacity: 1;
		}
	}
</style>
