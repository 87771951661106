import config from './config.js';
import * as Core from '@Core/index.js';
import App from './app.vue';
import router from './router/index.built.js';
import * as Store from './store/index.js';

// -------------------------------------- BOOSTRAP THE APP ---------------------------------
Core.EnvAccess.blockAccess();

window.location.hostname.includes('non-prod') || window.location.hostname.includes('sandbox');

const VueApp = Core.Vue.createApp(App).use(Core.Pinia.createPinia()).use(router);

// initialise store and stuff
Core.init(VueApp, config); // initialise core, only this one time
Store.init();

Core.Tracking.init(Core.AppConfig.config.GOOGLE_TRACKING);
Core.Hotjar.init();
Core.Feedback.config(Store);
VueApp.mount('#app');

// start chat with a delay (perf reasons)
setTimeout(function () {
	Core.Feedback.init();
}, 5000);
