import * as Vue from 'vue';
import * as Pinia from 'pinia';
import * as VueRouter from 'vue-router';

import * as AppConfig from '@Core/modules/AppConfig/index.js';
import * as Aws from './modules/Aws/index.js';
import AppLog from './modules/AppLog/index.js';

import { useLanguageStore } from '@Core/store/language.js';
import { useConversationStore } from '@Core/store/conversation.js';

import * as bpForms from './components/Blueprint/Forms/index.js';
import bpPopup from './components/Blueprint/Popup/index.vue';
import bpProgressCircle from './components/Blueprint/ProgressCircle/index.vue';
import bpProgressLine from './components/Blueprint/ProgressLine/index.vue';
import bpPill from './components/Blueprint/Pill/index.vue';
import bpAccordion from './components/Blueprint/Accordion/index.vue';
import bpLazy from './components/Blueprint/Lazy/index.vue';
import bpLoader from './components/Blueprint/Loader/index.vue';
import bpSectionProgress from './components/Blueprint/SectionProgress/index.vue';
import bpMemoryCallout from './components/Blueprint/MemoryCallout/index.vue';
import bpChart from './components/Blueprint/Chart/index.vue';

// -------------------------------------- RUN ----------------------------------------------
let VueApp;
const Store = {};

/**
 * Initialisation function to prepare all the Core functionality, including creating vueJS app.
 * @param {object} theApp Provide your vue app object
 * @param {object} config config object
 */
export function init(theApp, config) {
	// ADD GLOBAL APP LOG
	AppLog();

	// create base Vue App
	AppConfig.set(config); // app configuration set
	Aws.init(); // initialise aws config and shit
	VueApp = theApp; // assign so we can use it

	// store stuff
	useLanguageStore().update(); // initialise language stuff for CORE
	Store.conversation = useConversationStore();

	// Create all global components for the app (Blueprint components only!)
	VueApp.component('BlueprintInput', bpForms.Input);
	VueApp.component('BlueprintTextarea', bpForms.Textarea);
	VueApp.component('BlueprintCheckbox', bpForms.Checkbox);
	VueApp.component('BlueprintToggle', bpForms.Toggle);
	VueApp.component('BlueprintFancyToggle', bpForms.FancyToggle);
	VueApp.component('BlueprintRadio', bpForms.Radio);
	VueApp.component('BlueprintSelect', bpForms.Select);
	VueApp.component('BlueprintHorizontalRadio', bpForms.HorizontalRadio);
	VueApp.component('BlueprintUpload', bpForms.Upload);
	VueApp.component('BlueprintAddress', bpForms.Address);
	VueApp.component('BlueprintPopup', bpPopup);
	VueApp.component('BlueprintProgressCircle', bpProgressCircle);
	VueApp.component('BlueprintProgressLine', bpProgressLine);
	VueApp.component('BlueprintPill', bpPill);
	VueApp.component('BlueprintAccordion', bpAccordion);
	VueApp.component('BlueprintLazy', bpLazy);
	VueApp.component('BlueprintLoader', bpLoader);
	VueApp.component('BlueprintSectionProgress', bpSectionProgress);
	VueApp.component('BlueprintMemoryCallout', bpMemoryCallout);
	VueApp.component('BlueprintChart', bpChart);

	app.log('APP', 'initialised');
}

// -------------------------------------- EXPORTS ------------------------------------------
export { VueApp };

// libraries
export { Vue };
export { Pinia };
export { VueRouter };
export { Store };

// components (vue) - PREFIXED with "c", always!
export { default as cHeader } from './components/Header/index.vue';
export { default as cUserMenu } from './components/UserMenu/index.vue';
export { default as cNavigation } from './components/Navigation/index.vue';
export * as cDesign from './components/Design/index.js';
export { default as cErrorHandler } from './components/ErrorHandler/index.vue';
export { default as cAlert } from './components/Alert/index.vue';
export { default as cActivityLog } from './components/ActivityLog/index.vue';
export { default as cBreadcrumbs } from './components/Breadcrumbs/index.vue';
export { default as cConversations } from './components/Conversations/index.vue';
export { default as cQrCode } from './components/QrCode/index.vue';
export { default as cOneTimePass } from './components/OneTimePass/index.vue';
export { default as cMultiFactorAuth } from './components/AccountSecurity/MultiFactorAuth.vue';
export { default as cPhoneNumber } from './components/AccountSecurity/PhoneNumber.vue';
export { default as cChangePassword } from './components/AccountSecurity/ChangePassword.vue';

// modules
export * as EnvAccess from './modules/EnvAccess/index.js';
export * as Cookie from './modules/Cookie/index.js';
export * as Hash from './modules/Hash/index.js';
export * as Storage from './modules/Storage/index.js';
export * as Event from './modules/Event/index.js';
export * as Utils from './modules/Utils/index.js';
export * as Tracking from './modules/Tracking/index.js';
export * as Api from './modules/Api/index.js';
export * as Hotjar from './modules/Hotjar/index.js';
export * as Pubnub from './modules/Pubnub/index.js';
export * as Feedback from './modules/Feedback/index.js';
export * as StaticData from './modules/StaticData/index.js';
export { AppConfig };
export { Aws };
