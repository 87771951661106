/**
 @module Client/RouterPermissions
 */

import * as Core from '@Core/index.js';
import * as Store from '@Client/store/index.js';

// permission mapping
const PREDEFINED_PERMISSIONS = {
	loggedIn: loggedInPermission,
	loggedOut: loggedOutPermission,
	isOwner: isOwnerPermission,
	hasCompletedEsgProducts: hasCompletedEsgProductsPermission,
	isNotSubsidiary: isNotSubsidiaryPermission
};

// ----------- TODO -----------
// add all missing permissions like hasproducts, etc.
// review the application to find any permissions settings on begining of files
// add things like change business details, add collaborators etc, to be behind isOwner flag
// add unsubscribe to vueco2e button behind isOwner flag
// router permissions path change, seems to be not changing the templates (I saw 404 with default template)
// update router permissions to use user, business and product-store based checks
// vueco2e productl ink can be now accessed without having a product (no router permissions set properly!)

/* ---------------------------------- PRIVATE METHODS ------------------------------------------ */
/**
 * If the user is logged in, then we load the current page. If not, we load the home page
 * @param {string} path to selecter router
 * @returns {string} The response is a string that tells the router what to do next.
 */
function loggedInPermission(path) {
	if (Store.user.isLoggedIn) {
		return path;
	} else {
		return Core.AppConfig.config.ROUTER_PERMISSION_REDIRECTS.home;
	}
}

/**
 * Views under this permission require to never be accessible if user is logged in.
 * If the user is logged in, follow the flow manager.
 * If the user is not logged in, load the curren page.
 * @param {string} path target path
 * @returns {string} The response is a string that tells the router what to do next.
 */
function loggedOutPermission(path) {
	if (Store.user.isLoggedIn) {
		return Core.AppConfig.config.ROUTER_PERMISSION_REDIRECTS.dashboard;
	} else {
		return path;
	}
}

/**
 * Only allows access if user is owner of the current business
 * @param {string} path target path
 * @returns {string} The response is a string that tells the router what to do next.
 */
function isOwnerPermission(path) {
	if (Store.user.isOwner) {
		return path;
	} else {
		return Core.AppConfig.config.ROUTER_PERMISSION_REDIRECTS.dashboard;
	}
}

/**
 * It will allow to see the path only if store contains at least one completed product
 * Will also check if user is logged in
 * @param {string} path target path
 * @returns {string} returns requested path or changed if not allowed
 */
function hasCompletedEsgProductsPermission(path) {
	if (
		!Object.keys(Store.product.getCompletedProducts).length &&
		!Object.keys(Store.product.getDemoProduct).length &&
		!Store.user.isAuditor
	) {
		return Core.AppConfig.config.ROUTER_PERMISSION_REDIRECTS.dashboard;
	} else {
		return path;
	}
}

/**
 * Check if business is not a subsidiary, as these usually have some access limitations
 * @param {string} path target path
 * @returns {string} returns requested path or changed if not allowed
 */
function isNotSubsidiaryPermission(path) {
	if (Store.business.isSubsidiary) {
		return Core.AppConfig.config.ROUTER_PERMISSION_REDIRECTS.dashboard;
	} else {
		return path;
	}
}

/* ---------------------------------- PUBLIC METHODS ------------------------------------------ */
/**
 * check current path against permissions to figure out if they are allowed to see the page
 * @param {object} path object from vue router
 * @returns {string} Final path name to be resolved by the router
 */
export default async function (path) {
	if (!path.meta.permissions) return;

	let finalPathName = path.path;
	const permissions = path.meta.permissions.split(',');

	for (const item in permissions) {
		const newPath = await PREDEFINED_PERMISSIONS[permissions[item]](finalPathName);

		// making sure we don't check any further permission if an early one failed
		if (finalPathName !== newPath) {
			finalPathName = newPath;
			break;
		}
	}

	return finalPathName;
}
