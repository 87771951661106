/**
 @module Core/Feedback
 */
/* eslint-disable camelcase */

import './index.scss';
import * as Tracking from '@Core/modules/Tracking/index.js';

let store;

// ID config
const prodKey = '7sdbk9t639hfkzt2n7ca'; // PROD
const devKey = '8qdm61bdddyksmvmq85v'; // DEV

const ybugId =
	window.location.hostname.includes('non-prod') ||
	window.location.hostname.includes('sandbox') ||
	window.location.hostname.includes('localhost')
		? devKey
		: prodKey;

/**
 * Set store for Feedback so it can access extra data
 * @param {object} Store from the app
 */
function config(Store) {
	store = Store;
}

/**
 * Initialise YBug functionality to collect feedback and stuff!
 */
function init() {
	const username = `${store.user.getUser?.firstName} ${store.user.getUser?.lastName}`;

	window.ybug_settings = {
		id: ybugId,
		user: {
			name: username || 'Unknown',
			email: store.user.getUser?.email || '',
			selectedBusiness: store.app?.getSelectedBusiness?.id || '',
			businessName: store.business?.getCurrentBusiness?.essential?.name || '',
			businessOwner: store.user?.isOwner,
			appVersion: BUILD_VERSION
		},
		onload: function () {
			window.Ybug.on('open', function () {
				Tracking.click('feedback-WidgetOpen');
			});
		}
	};
	const ybug = document.createElement('script');
	ybug.type = 'text/javascript';
	ybug.async = true;
	ybug.src = `https://widget.ybug.io/button/${window.ybug_settings.id}.js`;
	const s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(ybug, s);
}

/* EXPOSE ------------------------------------------ */
export { config, init };
