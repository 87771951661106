<template>
	<div class="col-24 ActivityLog Space-top">
		<div
			class="row"
			data-testid="ActivityLog">
			<div class="col-lg-24">
				<div class="row ActivityLog--categories">
					<p class="col-2">{{ activityLanguage.activityCategories.type }}</p>
					<p class="col-8">{{ activityLanguage.activityCategories.activity }}</p>
					<p class="col-6 Text-center">{{ activityLanguage.activityCategories.user }}</p>
					<!-- @TODO: To be button in the future when backend sorting is finished -->
					<p class="col-6 Text-center">
						{{ activityLanguage.activityCategories.date }}
					</p>
					<div class="Space-top Space-bottom">
						<div class="ActivityLog--horizontalLine"></div>
					</div>
				</div>
				<p
					v-if="!state.loading"
					class="Text-center">
					{{ noActivityFound }}
				</p>
				<p
					v-if="!state.loading"
					class="Text-center">
					{{ state.errorMessage }}
				</p>
				<blueprint-loader
					v-if="state.loading"
					data-testid="blueprint-loader" />
				<activity-log-entry
					v-else
					:activity="computedActivities" />
			</div>
			<div
				v-if="state.nextPagePath"
				class="ActivityLog--loadMore">
				<blueprint-loader v-if="state.buttonLoading" />
				<button
					v-else-if="state.nextPagePath"
					class="Btn Btn-small"
					@click="loadMore">
					{{ activityLanguage.loadMoreButton }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import ActivityLogEntry from './ActivityLogEntry.vue';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'ActivityLog',

		// ---------- COMPONENTS ----------
		components: {
			'activity-log-entry': ActivityLogEntry
		},

		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: id for current business
			 * @property {string} businessId to find current business
			 */
			businessId: {
				required: true,
				type: String
			},
			/**
			 * PROP: Products for current business
			 * @property {object} products to display financial year and type
			 */
			products: {
				required: true,
				type: Object
			}
		},

		// ---------- SETUP ----------
		setup(props) {
			const state = Core.Vue.reactive({
				loading: false,
				buttonLoading: false,
				activities: [],
				nextPagePath: '',
				errorMessage: ''
			});
			const language = useLanguageStore();
			const activityLanguage = language.string.cActivityLog;
			const activitiesSortOrder = Core.Vue.ref('descending');

			const filterRadioOptions = Core.Vue.ref([
				{
					name: 'All',
					value: 'all'
				},
				{
					name: 'Products',
					value: 'products'
				},
				{
					name: 'My user',
					value: 'user'
				},
				{
					name: 'Company',
					value: 'company'
				}
			]);
			const filteredValue = Core.Vue.ref('all');
			/**
			 * function for sorthing by date and used by sort method
			 * @param {string} a parametar for sort function
			 * @param {string} b parametar for sort function
			 * @returns {Function} use in sort method
			 */
			const sortActivities = (a, b) => {
				return new Date(b.timestamp) - new Date(a.timestamp);
			};

			/**
			 * get activities by business id from API
			 */
			Core.Vue.onBeforeMount(async () => {
				state.loading = true;
				try {
					const response = await Core.Api.get(`/business/${props.businessId}/audit-log`);
					state.activities = response.body.entries.sort(sortActivities);
					state.nextPagePath = response.body.nextPagePath;
				} catch {
					state.errorMessage = language.string.cActivityLog.apiErrorMessage;
				} finally {
					state.loading = false;
				}
			});

			/**
			 * DUMMY FUNCTION(waiting on backend): Make a request to return sorted activities descending/ascending from database
			 * @returns {Array} sorted activities
			 */
			function switchActivitiesOrder() {
				//api call to switch order ascending or descending by date
				// const response = await Core.Api.get(`/business/${props.businessId}/audit-log/order?`);
				activitiesSortOrder.value === 'descending'
					? (activitiesSortOrder.value = 'ascending')
					: (activitiesSortOrder.value = 'descending');

				return ['many activities'];
			}

			/**
			 * If there are more activities, load them
			 */
			const loadMore = async () => {
				Core.Tracking.click('AativityLog-LoadMore');
				state.buttonLoading = true;
				try {
					const response = await Core.Api.get(`${state.nextPagePath}`);
					state.activities = [...state.activities, ...response.body.entries].sort(
						sortActivities
					);
					state.nextPagePath = response.body.nextPagePath;
				} finally {
					state.buttonLoading = false;
				}
			};

			/**
			 * Check if there is no activity
			 * @returns {string} for no activity found
			 */

			const noActivityFound = Core.Vue.computed(() => {
				return state.activities.length === 0
					? language.string.cActivityLog.noActivityFound
					: '';
			});

			/**
			 * Sort the activities by date and arrange them in date-by sections
			 * @returns {object} activity used for displaying all activities
			 */
			const computedActivities = Core.Vue.computed(() => {
				const activity = {};
				state.activities.forEach((item) => {
					const currentItemDate = Core.Utils.getDateHistory(item.timestamp);
					activity[currentItemDate] = activity[currentItemDate] || [];

					//.reactive to enable showMetaData to change
					const newItem = Core.Vue.reactive({
						...item,
						showMetaData: false
					});
					if (props.products[item.productId]) {
						newItem.product = props.products[item.productId];
					}
					activity[currentItemDate].push(newItem);
				});

				return activity;
			});
			return {
				computedActivities,
				state,
				loadMore,
				language,
				noActivityFound,
				filterRadioOptions,
				filteredValue,
				activityLanguage,
				switchActivitiesOrder
			};
		}
	};
</script>

<style lang="scss">
	@include block('ActivityLog') {
		@include element('loadMore') {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}

		@include element('productIcon') {
			width: 100px;
		}
		@include element('horizontalLine') {
			background-color: var(--color-greyEnd);
			opacity: 0.2;
			width: 100%;
			height: 1px;
		}

		@include element('productsFilter') {
			background-color: var(--color-grey900);
			border-radius: 2rem;
			display: flex;
			justify-content: center;
		}
	}
</style>
