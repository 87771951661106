/**
 @module Core/Cookie
 */

const assignedColours = {};
const COLORS = [
	'#d3d3d3', // light gray
	'#bee1e3', // duck egg
	'#a7fcdb', // mint
	'#b19882', // sepia
	'#ed91b6', // rose
	'#f1956e', // coral
	'#ffd7fc', // baby pink
	'#c3c2e2', // smoke
	'#fcb96b', // peach
	'#969696', // dark gray
	'#ba97ff', // lavender
	'#ead376' // apricot
];

// -------------------------------------- PUBLIC METHODS ----------------------------------------------
/**
 * Retuns value of a selected cookie name
 */
export default function () {
	// if url param set, set session storage to not lose it.
	if (location.hash.indexOf('debug=true') > -1) {
		window.sessionStorage.setItem('debug', 'true');
	}

	// set global object "app"
	window.app = {};
	window.app.log = function () {
		if (location.hostname === 'localhost' || sessionStorage.getItem('debug')) {
			// pick right colour for this group, or create new
			let currentColour = '#fff';
			if (assignedColours[arguments[0].toLowerCase()]) {
				currentColour = assignedColours[arguments[0].toLowerCase()];
			} else {
				currentColour = COLORS[0];
				assignedColours[arguments[0].toLowerCase()] = currentColour;
				COLORS.splice(0, 1);
			}

			// eslint-disable-next-line
			console.log(
				`%c${arguments[0]} -> `,
				`font-weight: 600; background: ${currentColour}`,
				arguments[1],
				arguments[2] || ' '
			);
		}
	};
}
