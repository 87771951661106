<template>
	<div class="row ChangePassword">
		<blueprint-input
			id="currentPassword"
			class="col-18"
			type="password"
			label="Password"
			required
			value="Omnevue1234567"
			disabled />
		<div class="col-6">
			<button
				class="Btn Btn-outline Btn-fullWidth Btn-formAligned"
				data-testid="userSettings-passwordReset"
				@click="resetPassword">
				{{ changePasswordLanguage.resetPasswordButton }}
			</button>
		</div>
	</div>
</template>

<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language';

	export default {
		name: 'ChangePassword',
		props: {
			/**
			 * User data on which we want MFA
			 * @namespace Core_ChangePassword
			 * @property {object} user - user data object
			 */
			user: {
				type: Object,
				required: true
			}
		},

		setup(props) {
			const language = useLanguageStore();
			const changePasswordLanguage = language.string.cAccountSecurity;

			/**
			 * Reset password from profile flow and logout
			 * set session storage so login page knows reset password comes from PROFILE FLOW
			 *
			 */
			function resetPassword() {
				Core.Storage.session('resetFromProfile', props.user.email);
				Core.Aws.Auth.logout();
				Core.Tracking.click('User-ResetPassword');
			}

			return { resetPassword, changePasswordLanguage };
		}
	};
</script>

<style lang="scss"></style>
