<template>
	<div
		class="UserMenu"
		data-testid="core-userMenu">
		<div
			class="UserMenu--inner"
			:class="expanded ? 'is-expanded' : ''"
			tabindex="0"
			@click="
				!expanded && Core.Tracking.click('userMenu-open');
				expanded = !expanded;
			"
			@blur="expanded = false">
			<div class="UserMenu--initials">
				{{ initials }}
			</div>
			<div
				class="d-none d-md-inline-block UserMenu--name"
				data-testid="core-userMenu-name">
				<b>{{ name }}</b>
			</div>

			<span class="UserMenu--icon iconFont-chevron"></span>
		</div>
		<transition name="fade">
			<div
				v-show="expanded"
				class="UserMenu--submenu">
				<router-link
					class="UserMenu--subItem"
					to="/user"
					data-testid="core-userMenu-userProfile"
					@click="Core.Tracking.click('userMenu-settings')">
					<span class="UserMenu--subIcon iconFont-settings"></span
					>{{ language.string.cUserMenu.settings }}
				</router-link>
				<router-link
					class="UserMenu--subItem"
					to="/support"
					data-testid="core-userMenu-help"
					@click="Core.Tracking.click('userMenu-help')">
					<span class="UserMenu--subIcon iconFont-help"></span
					>{{ language.string.cUserMenu.help }}
				</router-link>

				<div
					class="UserMenu--subItem"
					data-testid="core-userMenu-logout"
					@click="
						Core.Tracking.click('userMenu-logout');
						Core.Aws.Auth.logout();
					">
					<span class="UserMenu--subIcon iconFont-logout"></span
					>{{ language.string.cUserMenu.logout }}
				</div>
			</div>
		</transition>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'UserMenu',

		// ---------- PROPS ----------
		props: {
			/**
			 * PROP: firstName of the user
			 * @namespace Core_UserMenu
			 * @property {string} firstName of the user
			 */
			firstName: {
				required: true,
				type: String
			},
			/**
			 * PROP: lastName of the user
			 * @namespace Core_UserMenu
			 * @property {string} lastName of the user
			 */
			lastName: {
				required: true,
				type: String
			}
		},

		// ---------- SETUP ----------
		setup(props) {
			const language = useLanguageStore();
			const expanded = Core.Vue.ref(false);
			const name = Core.Vue.computed(() => {
				return `${props.firstName} ${props.lastName}`;
			});

			const initials = Core.Vue.computed(() => {
				const nameSplit = name.value.split(' ');
				return nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
			});

			return { Core, language, initials, name, expanded };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('UserMenu') {
		position: relative;
		float: right;

		@include element('inner') {
			border-radius: var(--radius-huge);
			color: var(--color-greyEnd);
			cursor: pointer;
			padding: 10px 20px;
			transition: all var(--time-hover) ease-in-out;
			width: 100%;
			display: flex;
			align-items: baseline;

			&:hover {
				background-color: var(--color-greyStart);
				color: var(--color-primary);
			}

			&.is-expanded {
				background-color: var(--color-greyStart);

				& .UserMenu--icon {
					transform: rotate(180deg);
				}
			}
		}

		@include element('initials') {
			background-color: var(--color-black);
			border-radius: 50%;
			color: var(--color-quaternary);
			display: inline-block;
			height: 40px;
			line-height: 40px;
			text-align: center;
			vertical-align: bottom;
			width: 40px;
			margin-right: var(--space-single);
		}

		@include element('name') {
			display: inline-block;
			height: 20px;
			overflow: hidden;
			width: 190px;
		}

		@include element('icon') {
			display: inline-block;
			transition: all var(--time-hover) ease-in-out;
		}

		@include element('submenu') {
			background-color: var(--color-greyStart);
			border-radius: var(--radius-huge);
			box-shadow: 0px 6px 5px -2px transparentize($color-black, 0.8);
			margin-top: var(--space-single);
			padding: 10px 20px;
			position: absolute;
			width: 300px;
			z-index: 100;
			right: 0;
		}

		@include element('subItem') {
			font-size: var(--text-tiny);
			padding: 5px 0;
			display: block;
			cursor: pointer;

			&:hover {
				color: var(--color-primary);
			}
		}

		@include element('subIcon') {
			vertical-align: middle;
			margin-right: 10px;
			margin-bottom: 2px;
			font-size: 20px !important;
			line-height: 24px;
		}

		@include element('hr') {
			border: none;
			border-top: 1px solid var(--color-grey800);
			height: 1px;
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
</style>
