/**
 * @module Core/Hotjar
 */

/**
 * Initialize Hotjar tracking code and prepare HOTJAR_FEEDBACK event to trigger widget
 *
 */
export function init() {
	// TODO: CONNECT USER-CONFIGURATION TO HOTJAR CONFIG ONCE PAID
	const NON_PROD = ['non-prod', 'localhost', 'sandbox'];
	const isProd = !NON_PROD.some((string) => window.location.href.includes(string));

	//Tracking code function specific to CLIENT APP
	if (isProd) {
		(function (h, o, t, j, a, r) {
			h.hj =
				h.hj ||
				function () {
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = { hjid: 3247896, hjsv: 6 };
			a = o.getElementsByTagName('head')[0];
			r = o.createElement('script');
			r.async = 1;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			a.appendChild(r);
		})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
	}
}
