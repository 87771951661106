import * as Core from '@Core/index.js';

/**
 * Transform API reply where each metric has only on value into format understandable by graph component
 * @private
 * @param {object} responseData translated dataset from the backend
 * @param {Array} categories array of categories for products
 * @param {boolean} flip data to be product-grouped, instead of metric-grouped
 * @returns {object} results with {categories, series} data
 */
export function transformSingleValues(responseData, categories, flip = false) {
	const finalData = {
		series: [],
		categories: []
	};

	if (!flip) {
		// set categories, no changes
		finalData.categories = categories;

		// transform the data into series
		for (const item in responseData) {
			for (const metric in responseData[item]) {
				const currentMetric = responseData[item][metric];

				// create final metrics and merge every next dataset with the first one to create proper series
				if (item === '0') {
					finalData.series[metric] = currentMetric;
				} else if (finalData.series[metric]) {
					finalData.series[metric].data =
						finalData.series[metric].data.concat(currentMetric.data) ?? [];
				}
			}
		}
	} else {
		// create new series
		for (const item in categories) {
			finalData.series.push({
				name: categories[item],
				data: []
			});
		}

		// populate all metrics
		for (const item in responseData) {
			for (const metric in responseData[item]) {
				const currentMetric = responseData[item][metric];

				finalData.series[item].data = finalData.series[item].data.concat(
					currentMetric.data
				);

				if (item === '0') {
					finalData.categories.push(currentMetric.name);
				}
			}
		}
	}

	return finalData ?? { data: [] };
}

/**
 * Transform API reply where each metric has multiple values (like monthly into format understandable by graph component
 * @private
 * @param {object} responseData translated dataset from the backend
 * @returns {Array} metrics directly from the backend, no modification of the data format
 */
export function transformSummaryValues(responseData) {
	return (
		responseData[0] ?? [
			{
				data: [],
				name: '',
				label: '',
				metric: '',
				renderedValues: [],
				shortLabel: '',
				values: []
			}
		]
	);
}

/**
 * Transform API reply where each metric has multiple values (like monthly into format understandable by graph component
 * @private
 * @param {object} responseData translated dataset from the backend
 * @param {Array} categories end year for this product (Array of 1 item
 * @param {number} [financialYearEnd=0] financial year end month number
 * @todo we might want to review categories param as it doesn't make sense to be array
 * @returns {object} results with {categories, series} data
 */
export function transformMonthlyValues(responseData, categories, financialYearEnd = 0) {
	//@TODO: If we need to show data for 2 years, double check this function as probably it should be modified
	const finalData = {
		series: [],
		categories: []
	};

	for (const item in responseData) {
		const currentYearMetric = responseData[item];
		// set categories (month and year)
		for (const metricCount in currentYearMetric[0].data) {
			const correctMonth = Core.Utils.getMonthName(
				Number(metricCount) + financialYearEnd + 1,
				false
			); // crate month name

			// we might need to cover 2 different years (likely)
			const calendarYearModifier = Number(metricCount) + financialYearEnd >= 12 ? 0 : 1;

			finalData.categories.push(
				`${correctMonth} ${Number(categories[item]) - calendarYearModifier}`
			); // create new graph
		}

		// set series
		for (const metricData in currentYearMetric) {
			const currentMetric = currentYearMetric[metricData];

			// when running loop first time, set the initial objects which later will be extended with more loops
			if (item === '0') {
				finalData.series.push({
					name: currentMetric.name,
					data: currentMetric.data
				});
			} else {
				finalData.series[metricData].data = finalData.series[metricData].data.concat(
					currentMetric.data
				);
			}
		}
	}

	return finalData ?? { series: [], categories: [] };
}
