/*
 * This is environment configuration file which will expose all the configs and stuff
 */

import * as Core from '@Core/index.js';

// Product LOGO IMAGES
import vuecarbon from '@Core/assets/drawings/vuecarbon-vertical.png';
import vueesg from '@Core/assets/drawings/vueesg-logo-vertical.png';
import vuecarbonHorizontal from '@Core/assets/drawings/vuecarbon-logo.png';
import vueesgHorizontal from '@Core/assets/drawings/vueesg-logo.png';

// Add flags here that will define specifics of the product (popular, new, exclusive, etc)
const FLAGS = {
	MOST_POPULAR: 'MOST_POPULAR'
};

// @TODO this config is now crap. It has to be rewritten and the whole app updated.
// we should have never added PRODUCTS object and PRODUCTS_OFFERING should be updated with flags and extra details you needed to add.
// OR if PRODUCTS{} was added, all others should become part of it.

export const DEFAULT_CONFIG = {
	// Oldest possible year to buy a product, currently is 2018, affects multiple places
	MIN_BUY_YEAR: 2018,
	PRODUCTS_OFFERING: [
		{
			id: '25sNI22EztvtTk3Zmx5m2UYaVEI',
			type: 'ASSURE',
			currency: 'GBP',
			billingFrequency: 'YEARLY',
			variant: 'ASSURE_FREE_2_YEAR',
			allowedUpgrades: {
				audit: true
			}
		},
		{
			id: '29NeEPlfjwRcWkpgakhyLo7gjsp',
			type: 'ASSURE',
			currency: 'GBP',
			billingFrequency: 'YEARLY',
			variant: 'ASSURE_1_YEAR',
			allowedUpgrades: {
				audit: true
			}
		},
		{
			id: '2ieKn7Au2KElLSu4CJPbOr3f4w8',
			type: 'CARBON',
			currency: 'GBP',
			billingFrequency: 'YEARLY',
			variant: 'CARBON_FREE_2_YEAR',
			allowedUpgrades: {
				audit: true
			}
		},
		{
			id: '2ieKn9uNcOZ2wMoIh4QbO2PDpFs',
			type: 'CARBON',
			currency: 'GBP',
			billingFrequency: 'YEARLY',
			variant: 'CARBON_1_YEAR',
			allowedUpgrades: {
				audit: true
			}
		}
	],

	// ------ PRICES ------
	VUEESG_PRICES: {
		pricesBySize: {
			'1_49': 1999,
			'50_249': 2699,
			'250+': 4199
		}
	},
	VUEESG_UPGRADE: {
		pricesBySize: {
			'1_49': 999,
			'50_249': 1349,
			'250+': 2099
		}
	},
	// Prices need to be updated here
	VUECARBON_PRICES: {
		pricesBySize: {
			'1_49': 1499,
			'50_249': 2099,
			'250+': 3599
		}
	},
	VUECARBON_UPGRADE: {
		pricesBySize: {
			'1_49': 999,
			'50_249': 1349,
			'250+': 2099
		}
	},

	PRICE_PER_SUBSIDIARY: 499,
	VAT_VALUE: 0.2,

	// config to control the products, if you want to change product flow then change here
	PRODUCTS: {
		vuecarbon: {
			type: 'CARBON',
			name: 'vuecarbon',
			flags: [FLAGS.MOST_POPULAR],
			variants: {
				specialOffer: 'CARBON_FREE_2_YEAR',
				regularOffer: 'CARBON_1_YEAR'
			},
			logo: vuecarbon,
			logoHorizontal: vuecarbonHorizontal,
			apiOptions: {
				endYear: true,
				upgrades: true
			}
		},
		vueesg: {
			type: 'ASSURE',
			name: 'vueesg',
			flags: [],
			variants: {
				specialOffer: 'ASSURE_FREE_2_YEAR',
				regularOffer: 'ASSURE_1_YEAR'
			},
			logo: vueesg,
			logoHorizontal: vueesgHorizontal,
			apiOptions: {
				endYear: true,
				upgrades: true
			}
		}
	},
	// Router permissions
	ROUTER_PERMISSION_REDIRECTS: {
		home: '/',
		dashboard: '/dashboard'
	}
};

const localConfig = Core.AppConfig.getEnvConfig();

// configuration for each env
export default {
	localhost: {
		...DEFAULT_CONFIG,
		AWS_REGION: localConfig.AWS_REGION,
		MAPS_KEY: localConfig.MAPS_KEY,
		AWS_POOL_ID: localConfig.AWS_POOL_ID,
		AWS_CLIENT_ID: localConfig.AWS_CLIENT_ID,
		AUTH_DOMAIN: localConfig.AUTH_DOMAIN,
		GOOGLE_TRACKING: 'G-Z64R2XTR9K',
		API: localConfig.CLIENT_API,
		STRIPE_KEY: localConfig.STRIPE_KEY,
		LOGIN_REDIRECT: 'http://localhost:3000',
		PUBNUB_P: localConfig.PUBNUB_P,
		PUBNUB_S: localConfig.PUBNUB_S,
		FUSE_API_KEY: localConfig.FUSE_API_KEY,
		PUBLIC_BUCKET: localConfig.PUBLIC_BUCKET
	},

	'i1-client.sandbox.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.I1.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.I1.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.I1.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.I1.CLIENT_LOGIN_REDIRECT,
		API: Core.AppConfig.CORE_CONFIG.I1.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.I1.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.I1.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.I1.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.I1.PUBLIC_BUCKET
	},

	'i2-client.sandbox.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.I2.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.I2.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.I2.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.I2.CLIENT_LOGIN_REDIRECT,
		API: Core.AppConfig.CORE_CONFIG.I2.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.I2.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.I2.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.I2.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.I2.PUBLIC_BUCKET
	},

	'i3-client.sandbox.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.I3.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.I3.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.I3.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.I3.CLIENT_LOGIN_REDIRECT,
		API: Core.AppConfig.CORE_CONFIG.I3.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.I3.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.I3.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.I3.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.I3.PUBLIC_BUCKET
	},

	'i4-client.sandbox.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.I4.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.I4.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.I4.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.I4.CLIENT_LOGIN_REDIRECT,
		API: Core.AppConfig.CORE_CONFIG.I4.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.I4.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.I4.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.I4.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.I4.PUBLIC_BUCKET
	},

	'i5-client.sandbox.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.I5.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.I5.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.I5.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.I5.CLIENT_LOGIN_REDIRECT,
		API: Core.AppConfig.CORE_CONFIG.I5.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.I5.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.I5.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.I5.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.I5.PUBLIC_BUCKET
	},

	'dev-client.non-prod.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.DEV.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.DEV.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.DEV.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.DEV.CLIENT_LOGIN_REDIRECT,
		GOOGLE_TRACKING: 'G-Z64R2XTR9K',
		API: Core.AppConfig.CORE_CONFIG.DEV.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.DEV.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.DEV.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.DEV.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.DEV.PUBLIC_BUCKET
	},

	'stage-client.non-prod.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.STAGE.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.STAGE.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.STAGE.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.STAGE.CLIENT_LOGIN_REDIRECT,
		GOOGLE_TRACKING: 'G-N7Y413S7DZ',
		API: Core.AppConfig.CORE_CONFIG.STAGE.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.STAGE.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.STAGE.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.STAGE.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.STAGE.PUBLIC_BUCKET
	},

	'showcase-client.non-prod.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.SHOWCASE.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.SHOWCASE.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.SHOWCASE.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.SHOWCASE.CLIENT_LOGIN_REDIRECT,
		API: Core.AppConfig.CORE_CONFIG.SHOWCASE.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.SHOWCASE.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.SHOWCASE.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.SHOWCASE.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.SHOWCASE.PUBLIC_BUCKET
	},

	'client.omnevue.com': {
		...DEFAULT_CONFIG,
		AWS_REGION: Core.AppConfig.CORE_CONFIG.AWS_REGION,
		MAPS_KEY: Core.AppConfig.CORE_CONFIG.MAPS_KEY,
		AWS_POOL_ID: Core.AppConfig.CORE_CONFIG.PROD.AWS_POOL_ID,
		AWS_CLIENT_ID: Core.AppConfig.CORE_CONFIG.PROD.AWS_CLIENT_ID,
		AUTH_DOMAIN: Core.AppConfig.CORE_CONFIG.PROD.AUTH_DOMAIN,
		LOGIN_REDIRECT: Core.AppConfig.CORE_CONFIG.PROD.CLIENT_LOGIN_REDIRECT,
		GOOGLE_TRACKING: 'G-F6NGEVTMFB',
		API: Core.AppConfig.CORE_CONFIG.PROD.CLIENT_API,
		STRIPE_KEY: Core.AppConfig.CORE_CONFIG.PROD.STRIPE_KEY,
		PUBNUB_P: Core.AppConfig.CORE_CONFIG.PROD.PUBNUB_P,
		PUBNUB_S: Core.AppConfig.CORE_CONFIG.PROD.PUBNUB_S,
		FUSE_API_KEY: Core.AppConfig.CORE_CONFIG.FUSE_API_KEY,
		PUBLIC_BUCKET: Core.AppConfig.CORE_CONFIG.PROD.PUBLIC_BUCKET
	}
};
